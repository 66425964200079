import React, { useEffect, useState } from "react";

import { Close, HouseCircleXmarkSolidV6, MapSolidV6, ThumbsUpSolidV6 } from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";
import Modal from "@shared/v2/Modal";
import Dnc from "./Dnc";
import Button from "@shared/v2/Button";
import Tooltip from "@shared/v2/Tooltip";
import ZillowLogo from "./ZillowLogo";
import CallInfo from "./CallInfo";
import { valueOrDash } from "./helpers";

const PropertyDetails = ({ property, closeModal, addProspectAsContact, callProspect }) => {
  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-w-full tw-h-full !tw-p-0"
      contentClassName="tw-m-auto !tw-p-0 tw-h-[100vh] tw-flex tw-flex-col tw-w-[825px] tw-right-0 !tw-absolute !tw-rounded-none"
      onHide={closeModal}
      show
    >
      <Modal.Header className="tw-mx-48px tw-mt-48px tw-mb-16px">
        <div className="tw-flex tw-flex-row tw-justify-between !tw-mt-0">
          <div className="tw-flex tw-flex-col tw-gap-4px">
            <span className="tw-text-12px tw-text-gray-50">MLS ID: {property.mlsId}</span>
            <div className="tw-flex tw-flex-row tw-gap-6px">
              <span className="tw-text-18px tw-text-gray-75">
                {property.streetAddress}, {property.city} {property.state} {property.postalCode}{" "}
              </span>
              {property.streetViewLink ? (
                <IconButton
                  onClick={() => window.open(property.streetViewLink, "_blank")}
                  schema="tertiary"
                  size="small"
                >
                  <MapSolidV6 size="m" />
                </IconButton>
              ) : null}
              {property.zillowLink ? (
                <IconButton
                  onClick={() => window.open(property.zillowLink, "_blank")}
                  schema="tertiary"
                  size="small"
                >
                  <ZillowLogo />
                </IconButton>
              ) : null}
            </div>
          </div>
          <IconButton onClick={closeModal} aria-label="Close" schema="tertiary">
            <Close />
          </IconButton>
        </div>
      </Modal.Header>
      <Modal.Body className="tw-flex tw-flex-col tw-overflow-y-auto tw-px-48px tw-relative tw-gap-32px tw-mb-48px">
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-flex tw-flex-col tw-gap-12px">
            <span className="tw-text-32px tw-font-bold tw-text-gray-75">{property.listPriceFormatted}</span>
            <div className="tw-flex tw-flex-row tw-items-center">
              <HouseCircleXmarkSolidV6 size="l" className="tw-mr-8px" />
              <span className="tw-text-gray-50 tw-font-semibold">Expired: {property.statusDateDisplay}</span>
              <div className="tw-border-0 tw-border-l tw-border-solid tw-border-gray-10 tw-h-20px tw-mx-12px"></div>
              <span className="tw-mr-8px tw-text-gray-50">Days on Market</span>
              <span className="tw-font-semibold tw-text-gray-75">{property.daysOnMarket}</span>
            </div>
          </div>
          <div className="tw-flex tw-flex-row tw-gap-32px tw-self-end">
            <PropertySpecifics name="Beds" value={property.beds} />
            <PropertySpecifics name="Baths" value={property.baths} />
            <PropertySpecifics name="Sq Ft" value={property.sqft} />
          </div>
        </div>
        <div>
          <Location lat={property.lat} lng={property.lng} address={property.streetAddress} />
        </div>
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Prospects for this Property" />
          {property.prospects?.length > 0 ? (
            <div className="tw-flex tw-flex-col tw-gap-16px">
              {property.prospects.map((prospect, i) => (
                <Prospect
                  prospect={prospect}
                  key={`prospect-${i}`}
                  addProspectAsContact={() => addProspectAsContact(property, prospect)}
                  callProspect={() => callProspect({ property, prospect })}
                />
              ))}
            </div>
          ) : (
            <span className="tw-text-gray-50">No prospects found for this property</span>
          )}
        </div>
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Call History" />
          {property.callHistory?.length > 0 ? (
            <div className="tw-flex tw-flex-col tw-overflow-y-auto tw-max-h-[300px] tw-pr-20px">
              {property.callHistory.map((call, i) => (
                <CallInfo key={`call-${i}`} call={call} includeBorder={i < property.callHistory.length - 1} />
              ))}
            </div>
          ) : (
            <span className="tw-text-gray-50">No calls logged for this property</span>
          )}
        </div>
        <hr className="tw-w-full !tw-m-0" />
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Owner Information" />
          <div className="tw-flex tw-flex-row tw-gap-48px">
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Name" value={property.owner?.name} />
              <DetailItem field="Address" value={property.owner?.address} />
            </div>
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Email" value={property.owner?.email} />
            </div>
          </div>
        </div>
        <hr className="tw-w-full !tw-m-0" />
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Listing Facts" />
          <div className="tw-flex tw-flex-row tw-gap-48px">
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Property Type" value={property.propertyType} />
              <DetailItem field="Year Built" value={property.yearBuilt} />
              <DetailItem field="SQFT" value={property.sqft} />
            </div>
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Lot Size (Acres)" value={property.lotSize} />
              <DetailItem field="County" value={property.postalCode} />
            </div>
          </div>
        </div>
        <hr className="tw-w-full !tw-m-0" />
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="MLS Information" />
          <div className="tw-flex tw-flex-row tw-gap-48px">
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="MLS ID" value={property.mlsId} />
              <DetailItem field="Status" value={property.status} />
              <DetailItem field="Expired Date" value={property.statusDateDisplay} />
            </div>
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Agent" value={property.agent?.name} />
              <DetailItem field="Phone" value={property.agent?.phone} />
              <DetailItem field="Company" value={property.agent?.company} />
            </div>
          </div>
        </div>
        <hr className="tw-w-full !tw-m-0" />
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Tax Information" />
          <div className="tw-flex tw-flex-row tw-gap-48px">
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Owner Name" value={property.tax?.name} />
              <DetailItem field="Phone Number" value={property.tax?.phone} />
              <DetailItem field="Mailing Address" value={property.tax?.address} />
            </div>
            <div className="tw-flex tw-flex-col tw-w-1/2 tw-gap-24px">
              <DetailItem field="Tax ID" value={property.tax?.taxId} />
              <DetailItem field="Owner Occupied" value={property.tax?.ownerOccupied} />
            </div>
          </div>
        </div>
        <hr className="tw-w-full !tw-m-0" />
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Agent Remarks" />
          <p className="!tw-m-0">{valueOrDash(property.agentRemarks)}</p>
        </div>
        <hr className="tw-w-full !tw-m-0" />
        <div className="tw-flex tw-flex-col">
          <SectionTitle text="Showing Instructions" />
          <p className="!tw-m-0">{valueOrDash(property.showingInstructions)}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PropertyDetails;

const SectionTitle = ({ text }) => {
  return <span className="tw-text-18px tw-font-bold tw-text-gray-55 tw-mb-16px">{text}</span>;
};

const DetailItem = ({ field, value }) => {
  return (
    <div className={`tw-flex tw-justify-end`}>
      <span className="tw-flex tw-mr-auto tw-text-gray-50">{field}</span>
      <span className="tw-text-gray-75 tw-w-1/2 tw-ml-auto tw-text-right">{valueOrDash(value)}</span>
    </div>
  );
};

const PropertySpecifics = ({ name, value }) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-6px">
      <span className="tw-text-18px tw-font-bold tw-text-gray-75">{valueOrDash(value)}</span>
      <span className="tw-text-12px tw-font-normal tw-text-gray-50">{name}</span>
    </div>
  );
};

const Location = ({ lat, lng, address }) => {
  useEffect(() => {
    const latLng = { lat, lng };
    const mapSettings = {
      zoom: 16,
      center: latLng,
      mapTypeId: "roadmap",
      panControl: false,
      disableDefaultUI: true,
    };

    const init = async () => {
      const { Map } = await google.maps.importLibrary("maps");
      const { Marker } = await google.maps.importLibrary("marker");

      const map = new Map(document.getElementById("listing-map"), mapSettings);
      new Marker({
        position: latLng,
        map,
        title: address,
      });
    };

    init();
  }, []);

  return <div id="listing-map" className="tw-h-[398px] tw-w-full"></div>;
};

const Prospect = ({ prospect, addProspectAsContact, callProspect }) => {
  return (
    <div className="tw-flex tw-flex-row tw-justify-between">
      <div className="tw-flex tw-flex-col tw-gap-2px">
        <div className="tw-flex tw-flex-row tw-gap-4px tw-items-center">
          <span
            className={`tw-text-14px tw-max-w-[250px] tw-font-semibold tw-overflow-hidden tw-overflow-ellipsis tw-text-gray-75 ${prospect.name ? "" : "tw-italic"}`}
          >
            {prospect.name || "No Name"}
          </span>
          {prospect.isRecommended && (
            <Tooltip
              trigger={<ThumbsUpSolidV6 />}
              content={<span>Recommended Prospect</span>}
              placement="right"
            />
          )}
        </div>
        <div className="tw-flex tw-flex-row tw-gap-4px">
          <span className="tw-text-12px tw-text-gray-75">{prospect.phoneFormatted || "--"}</span>
          <Dnc show={prospect.isDnc} />
        </div>
      </div>
      <div className="tw-flex tw-flex-row tw-gap-8px tw-items-center">
        <Button schema="primary" size="small" onClick={callProspect} disabled={!prospect.phone}>
          Call
        </Button>
        <Button schema="secondary" size="small" onClick={addProspectAsContact}>
          Add as Lead
        </Button>
      </div>
    </div>
  );
};
