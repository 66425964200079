import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { format, parse } from "date-fns";
import { camelCase } from "lodash";
import { caseTransformingAxios } from "@shared/v2/caseTransformingAxios";
import Modal, { Prompt } from "@shared/v2/Modal";
import Dropdown from "@shared/v2/Dropdown";
import TextInput from "@shared/v2/TextInput";
import DatePicker from "@shared/v2/DatePicker";
import { CircleInfoSolidV6 } from "@shared/v2/Icomoon";
import Button from "@shared/v2/Button";
import MlsListingDropdown from "@shared/MlsListingDropdown";
import useMlsListingDetails from "@shared/MlsListingDropdown/useMlsListingDetails";
import Loading from "../../../Goals/components/Loader";
import ListingPreviewCard from "../../../Transactions/ListingPreviewCard";
import HorizontalLine from "./HorizontalRule";
import useTimeOptions from "./hooks/useTimeOptions";
import useTags from "./hooks/useTags";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";
import useOpenHouseFormOptions from "./hooks/useOpenHouseFormOptions";

const validateForm = ({ openHouse, propertyDetails } = { openHouse: {}, propertyDetails: {} }) => {
  const errors = new Map();
  if (!openHouse.startDate) errors.set("startDate", "Date is required");
  if (!propertyDetails.streetAddress) errors.set("streetAddress", "Address is required");
  if (!propertyDetails.city) errors.set("city", "City is required");
  if (!propertyDetails.state) errors.set("state", "State is required");
  if (!propertyDetails.postalCode) errors.set("postalCode", "ZIP / Postal Code is required");

  return errors;
};

const useOpenHouseApi = () => {
  const [loading, setLoading] = useState(false);
  return {
    actions: {
      delete: (openHouseUuid) => {
        setLoading(true);
        return caseTransformingAxios
          .delete("/api/v4/reporting/open_house_dashboard", { params: { uuids: [openHouseUuid] } })
          .finally(() => setLoading(false));
      },
      save: (openHouseData) => {
        const isEdit = openHouseData?.openHouse?.uuid;
        setLoading(true);
        return caseTransformingAxios[isEdit ? "patch" : "post"](
          "/api/v4/reporting/open_house_dashboard",
          openHouseData,
        ).finally(() => setLoading(false));
      },
    },
    loading,
  };
};

const OpenHouseModal = ({ hosts, onClose, openHouse: originalOpenHouse }) => {
  const [showDelete, setShowDelete] = useState(false);
  const currentUser = useCurrentUser();
  const openHouseApi = useOpenHouseApi();
  const [openHouseData, setOpenHouseData] = useState(originalOpenHouse);
  const { openHouse, propertyDetails, tags } = openHouseData || {};
  const [mlsListing, setMlsListing] = useState(null);
  const [errors, setErrors] = useState(new Map());
  const isEdit = Boolean(openHouse?.uuid);
  const mlsListingDetails = useMlsListingDetails(mlsListing?.value);
  const formOptions = useOpenHouseFormOptions();
  const timeOptions = useTimeOptions();
  const availableTags = useTags();
  const previousAddressTagRef = useRef(propertyDetails?.streetAddress || "");

  const selectedTags = useMemo(() => {
    const newTags = (tags || []).map((tag) => {
      const isAddressTag = tag === previousAddressTagRef.current;
      return {
        label: tag,
        value: tag,
        meta: { isDisabled: tag === "Open House" || tag === "openhouseapp" || isAddressTag },
      };
    });
    return newTags;
  }, [tags]);

  useEffect(() => {
    if (!originalOpenHouse) {
      setMlsListing(null);
      setErrors(new Map());
    } else if (originalOpenHouse.openHouse.propertyId) {
      setMlsListing({ value: originalOpenHouse.openHouse.propertyId });
    }

    setOpenHouseData(originalOpenHouse);
  }, [originalOpenHouse]);

  useEffect(() => {
    const addressTag = propertyDetails?.streetAddress || "";
    setOpenHouseData((prev) => {
      const newData = prev
        ? {
            ...prev,
            tags: prev.tags?.map((tag) => (tag === previousAddressTagRef.current ? addressTag : tag)),
          }
        : null;

      previousAddressTagRef.current = addressTag;

      return newData;
    });
  }, [propertyDetails?.streetAddress]);

  useEffect(() => {
    setOpenHouseData((prev) =>
      prev
        ? {
            ...prev,
            openHouse: {
              ...prev.openHouse,
              propertyId: mlsListingDetails.data?.blossorId || prev.openHouse.propertyId,
            },
            propertyDetails: mlsListingDetails.data
              ? {
                  ...prev.propertyDetails,
                  streetAddress: mlsListingDetails.data?.streetAddress,
                  city: mlsListingDetails.data?.city,
                  state: mlsListingDetails.data?.state,
                  postalCode: mlsListingDetails.data?.postalCode,
                }
              : originalOpenHouse?.propertyDetails,
          }
        : null,
    );
  }, [mlsListingDetails.data]);

  const updateOpenHouseDetail = (key) => (value) =>
    setOpenHouseData((prev) => (prev ? { ...prev, openHouse: { ...prev.openHouse, [key]: value } } : null));

  const updatePropertyDetail = (key) => (e) => {
    const { value } = e.target;
    setOpenHouseData((prev) =>
      prev ? { ...prev, propertyDetails: { ...prev.propertyDetails, [key]: value } } : null,
    );
  };

  return (
    <>
      <Prompt
        closeOnEscape={false}
        closeOnClickOutside={false}
        containerClassName="!tw-z-[3001]"
        title="Delete Open House"
        description="Open house will be deleted from your Brivity Open House app. Are you sure you want to proceed?"
        onCancel={() => setShowDelete(false)}
        onPrimary={() => {
          openHouseApi.actions.delete(originalOpenHouse.openHouse.uuid).then(() => {
            onClose();
            setShowDelete(false);
          });
        }}
        cancelProps={{ isDisabled: openHouseApi.loading }}
        isPrimaryLoading={openHouseApi.loading}
        primaryOption="Yes, Delete"
        primaryProps={{ schema: "warning" }}
        show={showDelete}
      />
      <Modal
        containerClassName="!tw-z-[3001]"
        className="tw-flex tw-items-center tw-justify-center"
        contentClassName="tw-max-w-[620px] tw-w-[100vw] tw-max-h-[85vh] tw-flex tw-flex-col tw-gap-[32px]"
        show={!showDelete && Boolean(originalOpenHouse)}
        onHide={onClose}
        closeOnEscape={false}
        closeOnClickOutside={false}
      >
        <Modal.Header
          className="tw-px-[8px]"
          title={`${isEdit ? "Edit" : "Create"} Open House`}
          description={
            <span className="tw-text-gray-50">
              {isEdit
                ? "Changes made here will update the open house in your Brivity Open House app."
                : "Creates an open house in your Brivity Open House app. Import a listing or fill out the listing information below manually."}
            </span>
          }
          closeButton={!openHouseApi.loading}
        />

        <Modal.Body className="tw-flex-1 tw-flex tw-flex-col tw-overflow-auto tw-gap-[24px] tw-px-[8px]">
          <HorizontalLine oversized />
          {!mlsListingDetails.data && (
            <MlsListingDropdown
              label="Import Listing Details"
              placeholder="Search by address or MLS # to auto-populate listing data below"
              value={mlsListing}
              onChange={setMlsListing}
            />
          )}
          {mlsListingDetails.data && (
            <ListingPreviewCard
              listingData={mlsListingDetails.data}
              clearListing={() => {
                mlsListingDetails.actions.clear();
                setMlsListing(null);
              }}
            />
          )}
          {mlsListingDetails.loading && <Loading />}
          <HorizontalLine />
          <TextInput
            label="Address"
            isRequired
            value={propertyDetails?.streetAddress || ""}
            onChange={updatePropertyDetail("streetAddress")}
            error={errors.get("streetAddress")}
          />
          <TextInput
            label="City"
            isRequired
            value={propertyDetails?.city || ""}
            onChange={updatePropertyDetail("city")}
            error={errors.get("city")}
          />
          <div className="tw-flex tw-gap-[24px]">
            <TextInput
              containerClassName="tw-flex-1"
              label="State / Province"
              isRequired
              value={propertyDetails?.state || ""}
              onChange={updatePropertyDetail("state")}
              error={errors.get("state")}
            />
            <TextInput
              containerClassName="tw-flex-1"
              label="ZIP / Postal Code"
              isRequired
              value={propertyDetails?.postalCode || ""}
              onChange={updatePropertyDetail("postalCode")}
              error={errors.get("postalCode")}
            />
          </div>
          <div className="tw-flex tw-items-start tw-gap-[24px]">
            <DatePicker
              containerClassName="tw-flex-1"
              inputProps={{
                label: "Date",
                isRequired: true,
                error: errors.get("startDate"),
              }}
              value={openHouse?.startDate}
              onChange={(startDate) => {
                if (startDate?.indexOf("/") > 0) {
                  const date = parse(startDate, "MM/dd/yyyy", new Date());
                  updateOpenHouseDetail("startDate")(format(date, "yyyy-MM-dd"));
                  updateOpenHouseDetail("endDate")(format(date, "yyyy-MM-dd"));
                }
              }}
            />
            <div className="tw-flex-1 tw-flex tw-items-end tw-gap-[24px]">
              <Dropdown
                containerClassName="tw-flex-1"
                label="Time"
                isRequired
                options={timeOptions}
                value={timeOptions.find((t) => t.value === openHouse?.startTime) || null}
                onChange={(startTime) => updateOpenHouseDetail("startTime")(startTime.value)}
                menuShouldComeToFront
              />
              <Dropdown
                containerClassName="tw-flex-1"
                options={timeOptions}
                value={timeOptions.find((t) => t.value === openHouse?.endTime) || null}
                onChange={(endTime) => updateOpenHouseDetail("endTime")(endTime.value)}
                menuShouldComeToFront
              />
            </div>
          </div>
          {currentUser.account.isAdmin && (
            <Dropdown
              label={
                <div className="tw-flex tw-items-center tw-gap-[4px]">
                  Host
                </div>
              }
              isSearchable
              options={hosts}
              value={hosts.find((h) => h.value === openHouse?.hostPersonUuid) || null}
              onChange={(host) => updateOpenHouseDetail("hostPersonUuid")(host.value)}
              menuShouldComeToFront
            />
          )}
          <Dropdown
            label="Tags"
            placeholder="Search tags"
            isMulti
            isSearchable
            menuShouldComeToFront
            isOptionDisabled={(option, value) => option === value[0] || option === value[1]}
            options={availableTags}
            value={selectedTags}
            onChange={(newTags) =>
              setOpenHouseData((prev) => ({ ...prev, tags: newTags.map((t) => t.value) }))
            }
          />
          <TextInput
            label="Notes"
            multiline
            value={openHouse?.instructions || ""}
            onChange={(e) => updateOpenHouseDetail("instructions")(e.target.value)}
          />
        </Modal.Body>

        <Modal.Footer className="tw-flex tw-justify-between">
          <Button disabled={openHouseApi.loading} schema="tertiary" size="medium" onClick={onClose}>
            Cancel
          </Button>
          <div className="tw-flex tw-gap-[8px]">
            {isEdit && (
              <Button
                disabled={openHouseApi.loading}
                schema="secondary"
                size="medium"
                onClick={() => setShowDelete(true)}
              >
                Delete Open House
              </Button>
            )}
            <Button
              isLoading={openHouseApi.loading}
              schema="primary"
              size="medium"
              onClick={() => {
                const err = validateForm(openHouseData);
                setErrors(err);
                if (!err.size) {
                  openHouseApi.actions
                    .save({
                      ...openHouseData,
                      openHouse: { ...openHouseData.openHouse, formUuid: formOptions[0]?.uuid },
                    })
                    .then(onClose)
                    .catch((axiosErr) => {
                      const newErrors = new Map();
                      if (axiosErr.response?.data?.errors) {
                        axiosErr.response.data.errors.forEach((e) => {
                          newErrors.set(camelCase(e.path.replace(/\w+\./g, "")), e.msg);
                        });
                      }
                      setErrors(newErrors);
                    });
                }
              }}
            >
              {isEdit ? "Save" : "Create"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

OpenHouseModal.propTypes = {
  hosts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
  openHouse: PropTypes.shape(),
};

OpenHouseModal.defaultProps = {
  openHouse: null,
};

export default OpenHouseModal;
