import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import TextInput from "@shared/v2/TextInput";
import React, { useState } from "react";
import { saveSearch } from "./services";

const SaveSearch = ({ search, searchType, userId, onClose }) => {
  const [name, setName] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const saveAndClose = async () => {
    setIsSaving(true);
    await saveSearch(userId, name, search, searchType);

    setIsSaving(false);
    onClose(true);
  };

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      dialogClassName="tw-w-full"
      contentClassName="tw-m-auto tw-flex tw-flex-col tw-max-w-[691px] tw-gap-24px"
      onHide={onClose}
      show
    >
      <Modal.Header title={`Save ${searchType} Search`} closeButton />
      <Modal.Body>
        <div className="tw-mb-20px">{`The current location and filters will be saved for you. Please note that this saved search will not apply to other categories and will only be available for ${searchType.toLowerCase()}.`}</div>
        <TextInput
          className="tw-w-full tw-mb-32px"
          label="Name the search:"
          placeholder="Ex: [Search Query / Custom Search]"
          onChange={(e) => setName(e.target?.value || "")}
          value={name}
        />
        <hr className="tw-w-full !tw-m-0" />
      </Modal.Body>
      <Modal.Footer>
        <div className="tw-flex tw-justify-between">
          <Button schema="tertiary" size="medium" onClick={onClose}>
            Cancel
          </Button>
          <Button schema="primary" size="medium" onClick={saveAndClose} isLoading={isSaving} disabled={!name}>
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveSearch;
