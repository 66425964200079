import { createCustomSelector } from "@shared/hookHelpers";
import { isArray, mergeWith } from "lodash";

import {
  storeFetchedAgreements,
  storeFetchedAvailableTransactions,
  storeFetchedAddendums,
  agreementUploaded,
  agreementUpdated,
  removeDeletedAgreement,
  renameDocument,
  removeDocAgreement,
  toggleSuccessToastMsg,
  agreementReplaced,
  toggleLenderModal,
  toggleSuccessReferLoanToast,
  setLastRefferedDateTime,
} from "../../PersonDetail/actions/personDetailActionCreators";

const initialState = {
  error: false,
  person: {},
  timeline: {
    public_activity_events: [],
    timeline_events: [],
    task_events: [],
    gmail_events: [],
  },
  timeline_loading: true,
  timeline_loading_success: null,
  editPersonModalOpen: false,
  addAppointmentModalOpen: false,
  manageAccessModalOpen: false,
  addRelationshipModalOpen: false,
  addNoteModalOpen: false,
  relationships: [],
  documents: [],
  agreements: [],
  available_transactions: [],
  addendums: [],
  displayToast: {
    show: false,
    msg: "",
  },
  transactions: [],
  taskModalOpen: false,
  appointmentModalOpen: false,
  addListingAlertModalOpen: false,
  uploadingDocument: false,
  editSocialMediaModalOpen: false,
  webActivityModalOpen: false,
  assignedPlans: [],
  addAutoPlanModalOpen: false,
  deleteActionSuccess: false,
  editAppointmentModalOpen: false,
  lenderModalOpen: false,
  transactionCount: 0,
  tasks: [],
  appointments: [],
  notes: [],
  allTaskCount: 0,
  editAddressModalOpen: false,
  activeActionId: null,
  autoPlanPreviewModalOpen: false,
  planPreview: null,
  autoPlanDetailsOpen: false,
  assignedPlan: null,
  assignedActionId: null,
  deleteAssignedActionModalOpen: false,
  actionToDelete: null,
  hasActionError: false,
  actionErrorMessage: null,
  currentAction: null,
  isActionUpdating: false,
  selectedInteractionTab: 0,
  gmailReplyThreadId: null,
  gmailReplyThreadSubject: null,
  additionalRecipientsVal: [],
  CCval: [],
  BCCval: [],
  sendEmailError: null,
  isEmailSent: false,
  defaultHomeAppTabContent: null,
  transactionModalOpen: false,
  starred: false,
  externallyVisible: false,
  successMessageOpen: false,
  lastReferredDateTime: null,
};
const personDetail = (state = initialState, payload) => {
  const defaultState = { ...initialState, ...state };

  switch (payload.type) {
    case "UPDATE_PERSON_SUCCESS":
      return {
        ...state,
        person: mergeWith({}, { ...state.person }, { ...payload.person }, (objValue, srcValue) => {
          if (isArray(objValue)) return srcValue;
        }),
        updateErrors: [],
      };
    case "TOGGLE_PERSON_ERROR":
      return { ...state, error: payload.value || false };
    case "OPEN_EDIT_PERSON_MODAL":
      return {
        ...state,
        editPersonModalOpen: true,
      };
    case "CLOSE_EDIT_PERSON_MODAL":
      return {
        ...state,
        editPersonModalOpen: false,
        updateErrors: [],
      };
    case "UPDATE_PERSON_VALUES":
      return {
        ...state,
        person: {
          ...state.person,
          [payload.name]: payload.value,
        },
      };
    case "UPDATE_PUBLIC_ACTIVITY_EVENTS":
      return {
        ...state,
        timeline: {
          ...state.timeline,
          public_activity_events: [...state.timeline.public_activity_events, payload.data],
        },
      };
    case "SUBMIT_UPDATE_TIMELINE_EVENT":
      const elementsIndex = state.timeline.public_activity_events.findIndex(
        (element) => element.id == payload.data.id,
      );
      let newArray = [...state.timeline.public_activity_events];
      newArray[elementsIndex] = payload.data;
      return {
        ...state,
        timeline: {
          ...state.timeline,
          public_activity_events: newArray,
        },
      };
    case "DELETE_TIMELINE_NOTE":
      const index = state.timeline.public_activity_events.findIndex((element) => element.id == payload.id);
      const newArray2 = [...state.timeline.public_activity_events];
      newArray2.splice(index, 1);
      return {
        ...state,
        timeline: {
          ...state.timeline,
          public_activity_events: newArray2,
        },
      };
    case "SUBMIT_SEND_EMAIL":
      return {
        ...state,
        isEmailSent: true,
        timeline: {
          ...state.timeline,
          public_activity_events: [...state.timeline.public_activity_events, payload.data],
        },
      };

    case "CLEAR_EMAIL_STATE":
      return {
        ...state,
        isEmailSent: payload.bool,
        sendEmailError: null,
      };

    case "SUBMIT_ADD_APPOINTMENT":
      return {
        ...state,
        timeline: {
          ...state.timeline,
          public_activity_events: [...state.timeline.public_activity_events, payload.data],
        },
      };
    case "OPEN_ADD_APPOINTMENT_MODAL":
      return {
        ...state,
        addAppointmentModalOpen: true,
      };
    case "CLOSE_ADD_APPOINTMENT_MODAL":
      return {
        ...state,
        addAppointmentModalOpen: false,
      };
    case "OPEN_MANAGE_ACCESS_MODAL":
      return {
        ...state,
        manageAccessModalOpen: true,
      };

    case "CLOSE_MANAGE_ACCESS_MODAL":
      return {
        ...state,
        manageAccessModalOpen: false,
      };

    case "FETCH_INITIAL_TIMELINE":
      return {
        ...state,
        timeline: payload.data,
        timeline_loading: false,
        timeline_loading_success: true,
      };

    case "FETCH_TIMELINE_ERROR":
      return {
        ...state,
        timeline_loading: false,
        timeline_loading_success: false,
      };

    case "FETCHING_TIMELINE":
      return {
        ...state,
        timeline_loading: true,
        timeline_loading_success: null,
      };

    case "OPEN_ADD_RELATIONSHIP_MODAL":
      return {
        ...state,
        addRelationshipModalOpen: true,
      };

    case "CLOSE_ADD_RELATIONSHIP_MODAL":
      return {
        ...state,
        addRelationshipModalOpen: false,
      };
    case "TOGGLE_NOTE_MODAL":
      return {
        ...state,
        addNoteModalOpen: !state.addNoteModalOpen,
      };
    case "OPEN_ADD_NOTE_MODAL":
      return {
        ...state,
        addNoteModalOpen: true,
      };
    case "CLOSE_ADD_NOTE_MODAL":
      return {
        ...state,
        addNoteModalOpen: false,
      };
    case "FETCH_DOCUMENTS":
      return {
        ...state,
        documents: payload.data,
      };
    case storeFetchedAgreements().type:
      return {
        ...state,
        agreements: payload.data,
      };
    case storeFetchedAvailableTransactions().type:
      return {
        ...state,
        available_transactions: payload.data,
      };
    case storeFetchedAddendums().type:
      return {
        ...state,
        addendums: payload.data,
      };
    case agreementUploaded().type:
      return {
        ...state,
        agreements: [...state.agreements, payload.data[0]],
      };
    case agreementUpdated().type:
      return {
        ...state,
        agreements: state.agreements.map((agreement) =>
          agreement.id === payload.id ? { ...payload.data[0] } : agreement,
        ),
      };
    case agreementReplaced().type:
      return {
        ...state,
        agreements: state.agreements.map((agreement) =>
          agreement.id === payload.agreementId ? { ...agreement, ...payload.data } : agreement,
        ),
      };
    case "DOCUMENT_UPLOADED":
      return {
        ...state,
        documents: [...state.documents, payload.data],
      };
    case "DELETE_DOCUMENT":
      return {
        ...state,
        documents: state.documents.filter((document) => document.id !== payload.id),
      };
    case removeDocAgreement().type:
      return {
        ...state,
        agreements: state.agreements.map((agreement) => {
          // Check if the payload.id matches the agreement's document's id
          if (agreement.document?.id === payload.id) {
            return { ...agreement, document: null };
          }
          // Filter out the addendum with the matching id
          const filteredAddendums = agreement.addendums.filter((addendum) => addendum.id !== payload.id);

          // Return the updated agreement
          return { ...agreement, addendums: filteredAddendums };
        }),
      };
    case renameDocument().type:
      return {
        ...state,
        documents: state.documents.map((doc) =>
          doc.id === payload.id
            ? { ...doc, file_name: payload.newName, naked_name: `${payload.newName.split(".")[0]}` }
            : doc,
        ),
      };
    case removeDeletedAgreement().type:
      return {
        ...state,
        agreements: state.agreements.filter((agreement) => agreement.id !== payload.id),
      };
    case "FETCH_RELATIONSHIPS":
      return {
        ...state,
        relationships: payload.data,
      };
    case "RELATIONSHIP_ADDED":
      return {
        ...state,
        relationships: [...state.relationships, payload.data],
      };
    case "RELATIONSHIP_EDITED":
      const relationshipIndex = state.relationships.findIndex((element) => element.id === payload.data.id);
      let adjustedRelationshipArray = [...state.relationships];
      adjustedRelationshipArray[relationshipIndex] = payload.data;
      return {
        ...state,
        relationships: adjustedRelationshipArray,
      };
    case "RELATIONSHIP_DELETED":
      return {
        ...state,
        relationships: state.relationships.filter((relationship) => relationship.id !== payload.data),
      };
    case "STATUS_UPDATED":
      return {
        ...state,
        person: {
          ...state.person,
          status: payload.data.status,
        },
      };
    case "INTENT_UPDATED":
      return {
        ...state,
        person: {
          ...state.person,
          lead_type: payload.data.lead_type,
        },
      };
    case "IS_LEAD_UPDATED":
      return {
        ...state,
        person: {
          ...state.person,
          is_lead: payload.data,
        },
      };
    case "STORE_FETCHED_TRANSACTIONS":
      return {
        ...state,
        transactions: payload.data.listings || [],
        transactionCount: payload.data.meta.count,
      };

    case "OPEN_TASK_MODAL":
      return {
        ...state,
        taskModalOpen: true,
      };

    case "OPEN_APPOINTMENT_MODAL":
      return {
        ...state,
        appointmentModalOpen: true,
      };

    case "CLOSE_APPOINTMENT_MODAL":
      return {
        ...state,
        appointmentModalOpen: false,
      };
    case "CLOSE_TASK_MODAL":
      return {
        ...state,
        taskModalOpen: false,
      };

    case "OPEN_ADD_LISTING_ALERT_MODAL":
      return {
        ...state,
        addListingAlertModalOpen: true,
      };

    case "CLOSE_ADD_LISTING_ALERT_MODAL":
      return {
        ...state,
        addListingAlertModalOpen: false,
      };
    case "UPLOADING_DOCUMENT":
      return {
        ...state,
        uploadingDocument: payload.bool,
      };
    case "OPEN_EDIT_SOCIAL_MEDIA_MODAL":
      return {
        ...state,
        editSocialMediaModalOpen: true,
      };
    case "CLOSE_EDIT_SOCIAL_MEDIA_MODAL":
      return {
        ...state,
        editSocialMediaModalOpen: false,
      };
    case "OPEN_WEB_ACTIVITY_MODAL":
      return {
        ...state,
        webActivityModalOpen: true,
      };
    case "CLOSE_WEB_ACTIVITY_MODAL":
      return {
        ...state,
        webActivityModalOpen: false,
      };

    case "FETCH_ASSIGNED_PLANS":
      return {
        ...state,
        assignedPlans: payload.data,
      };

    case "CREATE_ASSIGNED_PLAN":
      return {
        ...state,
        assignedPlans: [...state.assignedPlans, payload.data],
        addAutoPlanModalOpen: false,
      };

    case "UPDATE_ASSIGNED_PLAN":
      return {
        ...state,
        assignedPlans: state.assignedPlans.map((plan) =>
          plan.id === payload.data.id ? { ...plan, ...payload.data } : plan,
        ),
      };

    case "OPEN_ADD_AUTO_PLAN_MODAL":
      return {
        ...state,
        addAutoPlanModalOpen: true,
      };

    case "CLOSE_ADD_AUTO_PLAN_MODAL":
      return {
        ...state,
        addAutoPlanModalOpen: false,
      };

    case "OPEN_DELETE_ASSIGNED_PLAN_MODAL":
      return {
        ...state,
        deleteAssignedPlanModalOpen: true,
        planToDelete: payload.planToDelete,
      };

    case "CLOSE_DELETE_ASSIGNED_PLAN_MODAL":
      return {
        ...state,
        deleteAssignedPlanModalOpen: false,
        planToDelete: {},
      };

    case "OPEN_EDIT_APPOINTMENT_MODAL":
      return {
        ...state,
        editAppointmentModalOpen: true,
      };
    case "CLOSE_EDIT_APPOINTMENT_MODAL":
      return {
        ...state,
        editAppointmentModalOpen: false,
      };
    case "UPDATE_APPOINTMENT":
      const appointmentIndex = state.timeline.public_activity_events.findIndex(
        (element) => element.id == payload.data.id,
      );
      let newAppointmentArray = [...state.timeline.public_activity_events];
      newAppointmentArray[appointmentIndex] = payload.data;
      return {
        ...state,
        timeline: {
          ...state.timeline,
          public_activity_events: newAppointmentArray,
        },
      };

    case "UPDATE_TASKS":
      const taskIndex = state.timeline.public_activity_events.findIndex(
        (element) => element.id == payload.data.id,
      );
      let newTaskArray = [...state.timeline.public_activity_events];
      newTaskArray[taskIndex] = payload.data;
      return {
        ...state,
        timeline: {
          ...state.timeline,
          public_activity_events: newTaskArray,
        },
      };
    case "REMOVE_APPOINTMENT":
      const appointmentArrayMinusAppointment = state.timeline.public_activity_events.filter(
        (element) => element.object_attributes.id !== payload.id,
      );
      return {
        ...state,
        timeline: {
          ...state.timeline,
          public_activity_events: appointmentArrayMinusAppointment,
        },
      };
    case "GET_TASKS":
      return {
        ...state,
        tasks: payload.data.tasks,
        allTaskCount: payload.data.meta.all_task_count,
      };
    case "GET_APPOINTMENTS":
      return {
        ...state,
        appointments: payload.data.tasks,
        allAppointmentCount: payload.data.meta.all_task_count,
      };
    case "UPDATE_CARD_TASKS":
      const updatedAppointments = state.appointments.map((task) =>
        task.id === payload.task.id ? payload.task : task,
      );
      const updatedTasks = state.tasks.map((task) => (task.id === payload.task.id ? payload.task : task));
      return {
        ...state,
        appointments: updatedAppointments,
        tasks: updatedTasks,
      };
    case "GET_NOTES":
      return {
        ...state,
        notes: payload.data,
      };

    case "OPEN_EDIT_ADDRESS_MODAL":
      return {
        ...state,
        editAddressModalOpen: true,
      };

    case "CLOSE_EDIT_ADDRESS_MODAL":
      return {
        ...state,
        editAddressModalOpen: false,
      };

    case "SHOW_AUTO_PLAN_PREVIEW":
      return {
        ...state,
        autoPlanPreviewModalOpen: true,
        planPreview: payload.autoPlan,
      };

    case "PREVIEW_ACTION":
      return {
        ...state,
        activeActionId: payload.actionId,
      };

    case "CLOSE_AUTO_PLAN_PREVIEW":
      return {
        ...state,
        addAutoPlanModalOpen: true,
        autoPlanPreviewModalOpen: false,
        planPreview: null,
      };

    case "SHOW_PLAN_DETAILS":
      return {
        ...state,
        autoPlanDetailsOpen: true,
        assignedPlan: payload.assignedPlan,
      };

    case "CLOSE_PLAN_DETAIL":
      return {
        ...state,
        autoPlanDetailsOpen: false,
        currentAction: null,
        assignedActionId: null,
        assignedPlan: null,
      };

    case "ASSIGNED_ACTION_PREVIEW":
      return {
        ...state,
        assignedActionId: payload.assignedAction.id,
        currentAction: payload.assignedAction,
      };

    case "SHOW_ASSIGNED_ACTION_DELETE_MODAL":
      return {
        ...state,
        deleteAssignedActionModalOpen: true,
        autoPlanDetailsOpen: false,
        actionToDelete: payload.actionToDelete,
      };

    case "CLOSE_ASSIGNED_ACTION_DELETE_MODAL":
      return {
        ...state,
        autoPlanDetailsOpen: true,
        deleteAssignedActionModalOpen: false,
      };

    case "DELETE_ACTION_SUCCESS":
      return {
        ...state,
        assignedPlan: {
          ...state.assignedPlan,
          assigned_actions: state.assignedPlan.assigned_actions.filter(
            (a) => a.id != payload.assignedActionId,
          ),
        },
        assignedActionId: null,
        deleteAssignedActionModalOpen: false,
        actionToDelete: null,
        hasActionError: false,
        actionErrorMessage: null,
        currentAction: null,
      };

    case "DELETE_ACTION_FAILURE":
      return {
        ...state,
        hasActionError: true,
        actionErrorMessage: payload.error,
      };

    case "UPDATE_ACTION_PENDING":
      return {
        ...state,
        isActionUpdating: true,
      };

    case "UPDATE_ACTION_SUCCESS":
      return {
        ...state,
        isActionUpdating: false,
        assignedPlan: {
          ...state.assignedPlan,
          assigned_actions: state.assignedPlan.assigned_actions.map((a) =>
            a.id === payload.assignedAction.id ? { ...a, ...payload.assignedAction } : a,
          ),
        },
      };

    case "UPDATE__ACTION_FAILURE":
      return {
        ...state,
        isActionUpdating: false,
      };

    case "OPEN_DELETE_CLOSE_ACTIONS_MODAL":
      return {
        ...state,
        autoPlanDetailsOpen: false,
        deleteAssignedPlanModalOpen: true,
        planToDelete: payload.planToDelete,
        actionErrorMessage: null,
        hasActionError: false,
      };

    case "SET_INTERACTION_TAB":
      return {
        ...state,
        selectedInteractionTab: payload.tabIndex,
      };

    case "SET_INTERACTION_TAB_WITH_CONTENT":
      return {
        ...state,
        defaultHomeAppTabContent: payload.defaultHomeAppTabContent,
        selectedInteractionTab: payload.tabIndex,
      };

    case "CLEAR_INTERACTION_TAB_CONTENT":
      return {
        ...state,
        defaultHomeAppTabContent: null,
      };

    case "SET_GMAIL_REPLY_THREAD":
      return {
        ...state,
        gmailReplyThreadId: payload.gmailReplyThreadId,
        gmailReplyThreadSubject: payload.gmailReplyThreadSubject,
        additionalRecipientsVal: payload.additionalRecipientsVal,
        CCval: payload.CCval,
        BCCval: payload.BCCval,
      };

    case "SUBMIT_ERROR_EMAIL":
      return {
        ...state,
        sendEmailError: payload.data,
      };

    case "UPDATE_HOME_APP_INVITE_SENT":
      return {
        ...state,
        person: {
          ...state.person,
          data: {
            ...state.person.data,
            attributes: {
              ...state.person.data.attributes,
              home_app_invite_sent: payload.home_app_invite_sent,
            },
          },
        },
      };

    case "TOGGLE_TRANSACTION_MODAL":
      return {
        ...state,
        transactionModalOpen: payload.value || !state.transactionModalOpen,
      };
    case "EXTERNALLY_VISIBLE":
      return {
        ...state,
        externallyVisible: payload.bool,
      };
    case "STARRED":
      return {
        ...state,
        starred: payload.bool,
      };
    case "SUBMIT_NOTE_SUCCESS":
      return {
        ...state,
        notes: [payload.note, ...state.notes],
      };
    case "UPDATE_NOTE_SUCCESS":
      return {
        ...state,
        notes: state.notes.map((note) => {
          return note.id === payload.note.id ? payload.note : note;
        }),
      };
    case "DELETE_NOTE_SUCCESS":
      return {
        ...state,
        notes: state.notes.filter((note) => note.id !== payload.note.id),
      };
    case "GET_NAV_LINKS":
      return {
        ...state,
        ...payload.data,
      };
    case toggleLenderModal().type: {
      return {
        ...state,
        lenderModalOpen: !state.lenderModalOpen,
      };
    }
    case toggleSuccessReferLoanToast().type: {
      return {
        ...state,
        successMessageOpen: !state.successMessageOpen,
      };
    }
    case toggleSuccessToastMsg().type: {
      return {
        ...state,
        displayToast: payload.data,
      };
    }
    case setLastRefferedDateTime().type: {
      return {
        ...state,
        lastReferredDateTime: payload.data,
      };
    }
    default:
      return defaultState;
  }
};

export default personDetail;

const useCreateSelector = createCustomSelector(({ personDetail }) => personDetail);

export const useLenderModalOpen = () => useCreateSelector((state) => state.lenderModalOpen);
export const usePerson = () => useCreateSelector((state) => state.person);
export const useSuccessMessageOpen = () => useCreateSelector((state) => state.successMessageOpen);
export const useLastReferredDateTime = () => useCreateSelector((state) => state.lastReferredDateTime);
