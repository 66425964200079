import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PlatformCTA = ({ children }) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement="top"
    overlay={
      <Tooltip id="platform-cta">
        <span>
          Filtering by property view/favorite criteria is a Brivity Platform feature. Contact
          sales@brivity.com to upgrade.
        </span>
      </Tooltip>
    }
  >
    {children}
  </OverlayTrigger>
);

PlatformCTA.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PlatformCTA;
