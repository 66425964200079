import React, { useState, useEffect } from "react";
import Proptypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import Dropdown from "@shared/v2/Dropdown";
import { useEventListenerAction } from "@shared/hooks/useEventListenerAction";
import { CLEAR_MODAL_FILTER_ID } from "../../shared/constants";

const AgreementDateSelect = ({
  previousSelectedExpirationDate,
  previousSelectedSignedDate,
  expirationDateOptions,
  signedDateOptions,
}) => {
  const [selectedSignedDate, setSelectedSignedDate] = useState(
    previousSelectedSignedDate?.length > 0
      ? signedDateOptions.find((option) => option.value === previousSelectedSignedDate[0])
      : [],
  );
  const [selectedExpiredDate, setSelectedExpiredDate] = useState(
    previousSelectedExpirationDate?.length > 0
      ? expirationDateOptions.find((option) => option.value === previousSelectedExpirationDate[0])
      : [],
  );

  useEventListenerAction(CLEAR_MODAL_FILTER_ID, () => {
    setSelectedExpiredDate([]);
    setSelectedSignedDate([]);
  });

  useEffect(() => {
    if (selectedExpiredDate || selectedSignedDate) {
      document.getElementById("leads_filter_selected_agreement_filters_expiration_date_range").value =
        selectedExpiredDate?.value || [];
      document.getElementById("leads_filter_selected_agreement_filters_signed_date_range").value =
        selectedSignedDate?.value || [];
    }
  }, [selectedExpiredDate, selectedSignedDate]);
  return (
    <Row className="m-b-10">
      <Col xs={6}>
        <input
          type="hidden"
          id="leads_filter_selected_agreement_filters_signed_date_range"
          name="leads_filter[selected_agreement_filters][signed_date_range][]"
          value=""
        />
        <Dropdown
          label="Signed Date Range"
          placeholder="Select..."
          options={signedDateOptions}
          value={selectedSignedDate}
          onChange={(e) => setSelectedSignedDate(e)}
          menuPlacement="bottom"
          labelClassName="!tw-font-normal"
        />
      </Col>
      <Col xs={6}>
        <input
          type="hidden"
          id="leads_filter_selected_agreement_filters_expiration_date_range"
          name="leads_filter[selected_agreement_filters][expiration_date_range][]"
          value=""
        />
        <Dropdown
          label="Expiration Date Range"
          placeholder="Select..."
          options={expirationDateOptions}
          value={selectedExpiredDate}
          onChange={(e) => setSelectedExpiredDate(e)}
          menuPlacement="bottom"
          labelClassName="!tw-font-normal"
        />
      </Col>
    </Row>
  );
};
AgreementDateSelect.propTypes = {
  previousSelectedExpirationDate: Proptypes.arrayOf(Proptypes.string),
  previousSelectedSignedDate: Proptypes.arrayOf(Proptypes.string),
  signedDateOptions: Proptypes.arrayOf(Proptypes.shape({ label: Proptypes.string, value: Proptypes.string })),
  expirationDateOptions: Proptypes.arrayOf(
    Proptypes.shape({ label: Proptypes.string, value: Proptypes.string }),
  ),
};
AgreementDateSelect.defaultProps = {
  previousSelectedExpirationDate: [],
  previousSelectedSignedDate: [],
  signedDateOptions: [],
  expirationDateOptions: [],
};
export default AgreementDateSelect;
