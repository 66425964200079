import React, { useState, useRef, useEffect } from "react";

import "./map.scss";
import CustomOverlay from "../../SearchListings/components/CustomOverlay";
import PropertyCard from "./PropertyCard";
import { formatSimplePrice } from "../../SearchListings/components/helpers";

const Map = ({
  locationQuery,
  setLocationQuery,
  properties,
  radius,
  onSelect,
  selected,
  onClickProperty,
  addProspectAsContact,
  callProspect,
}) => {
  const container = useRef(null);
  const [heightOffset, setHeightOffset] = useState(0);
  const [isDrawing, setIsDrawing] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const onDrawButtonClick = () => {
    setIsDrawing(!isDrawing);
    setLocationQuery(null);
  };

  const onPolygonDrawn = (polygon) => {
    setIsDrawing(false);
    setLocationQuery(polygon);
  };

  const onClickMarker = (index) => {
    if (index === selectedProperty) {
      deselectProperty();
      return;
    }

    setSelectedProperty(index);
    onClickProperty(properties[index].id);
  };

  const deselectProperty = () => {
    setSelectedProperty(null);
    onClickProperty(null);
  };

  useEffect(() => {
    const offset = container.current.getBoundingClientRect().top || 0;
    // 8 being the bottom padding
    setHeightOffset(offset + 8);
  }, []);

  useEffect(() => {
    deselectProperty();
  }, [locationQuery]);

  return (
    <div ref={container} className="tw-relative">
      <GoogleMap
        className="tw-w-full tw-rounded-[8px] opportunities-map"
        style={{ height: `calc(100vh - ${heightOffset}px)` }}
        isDrawing={isDrawing}
        locationQuery={locationQuery}
        radius={radius}
        setLocationQuery={onPolygonDrawn}
        onClick={deselectProperty}
      >
        {properties?.length > 0 &&
          properties.map((property, i) => (
            <CustomOverlay
              key={property.id}
              position={{ lat: property.lat, lng: property.lng }}
              pixelOffset={{ x: -32, y: -34 }}
              zIndex={selectedProperty === i ? 10 : "initial"}
            >
              <PropertyMarker
                price={property.listPrice}
                onClick={() => onClickMarker(i)}
                isSelected={selectedProperty === i}
              />
            </CustomOverlay>
          ))}
        {properties?.length > 0 && selectedProperty !== null && properties[selectedProperty] && (
          <CustomOverlay
            key={properties[selectedProperty].id}
            position={{ lat: properties[selectedProperty].lat, lng: properties[selectedProperty].lng }}
            pixelOffset={{ x: -150, y: -324 }}
            allowReposition={true}
            elementSize={{ width: 279, height: 284 }}
          >
            <PropertyCard
              property={properties[selectedProperty]}
              isSelected={selected.includes(properties[selectedProperty].id)}
              select={() => onSelect(properties[selectedProperty])}
              status="Expired"
              addProspectAsContact={addProspectAsContact}
              callProspect={callProspect}
            />
          </CustomOverlay>
        )}
      </GoogleMap>
      <div className="tw-absolute tw-top-24px tw-left-12px">
        <button
          className="tw-relative tw-font-bold tw-border-solid tw-border-gray-30 tw-bg-white tw-rounded-full tw-text-center tw-uppercase tw-border-2px tw-px-18px tw-py-6px tw-text-14d hover:tw-bg-gray-10"
          onClick={onDrawButtonClick}
        >
          {isDrawing ? "Clear" : "Draw Area"}
        </button>
      </div>
    </div>
  );
};

export default Map;

const GoogleMap = ({
  style,
  className,
  children,
  isDrawing,
  locationQuery,
  radius,
  setLocationQuery,
  onClick,
}) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [listeners, setListeners] = useState([]);
  const [drawingManager, setDrawingManager] = useState(null);
  const [shape, setShape] = useState(null);

  const mapOptions = {
    zoom: 11,
    center: { lat: 33.773, lng: -112.3321 },
    mapTypeId: "roadmap",
    disableDefaultUI: true,
    clickableIcons: false,
  };

  const addListener = (listener) => {
    setListeners([...listeners, listener]);
  };

  const createMap = () => {
    const m = new window.google.maps.Map(mapRef.current, mapOptions);
    const newListeners = [
      window.google.maps.event.addListener(m, "click", onClick), // Saving, was used for deselecting a marker
    ];

    setListeners(newListeners);
    setDrawingManager(createDrawingManager(window.google, m, setLocationQuery, addListener));

    m.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(zoomies(m));

    setMap(m);
  };

  useEffect(() => {
    return () => {
      for (const listener of listeners || []) {
        listener.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (isDrawing) {
      drawingManager?.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    } else {
      drawingManager?.setDrawingMode(null);
      shape?.setMap(null);
    }
  }, [isDrawing]);

  useEffect(() => {
    if (!map) {
      return;
    }

    if (shape) {
      shape.setMap(null);
    }

    if (locationQuery && locationQuery.isRadius) {
      const circle = new window.google.maps.Circle({
        strokeColor: "#666666",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: "#666666",
        fillOpacity: 0.1,
        map,
        center: locationQuery.location,
        radius: radius,
        clickable: false,
      });

      map.fitBounds(circle.getBounds());

      setShape(circle);
    }

    if (locationQuery && !locationQuery.isRadius) {
      const polygon = new window.google.maps.Polygon({
        strokeColor: "#666666",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: "#666666",
        fillOpacity: 0.1,
        map,
        paths: locationQuery.polygon,
        editable: true,
        draggable: false,
        clickable: false,
      });

      const listener = google.maps.event.addListener(polygon.getPath(), "set_at", (e) => {
        setLocationQuery({ isRadius: false, polygon: convertMvcArray(polygon.getPath()) });
      });

      addListener(listener);

      const bounds = new window.google.maps.LatLngBounds();
      locationQuery.polygon.forEach((p) => {
        bounds.extend(p);
      });

      map.fitBounds(bounds);

      setShape(polygon);
    }
  }, [locationQuery, map, radius]);

  useEffect(() => {
    if (!mapRef.current || map) {
      return;
    }

    const load = async () => {
      if (window.google) {
        createMap();
        return;
      }

      await sleep(10);

      return load();
    };

    load();
  }, [mapRef, map]);

  return (
    <div ref={mapRef} className={className} style={style}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </div>
  );
};

const createDrawingManager = (google, map, onPolygonComplete, addListener) => {
  const drawingManager = new google.maps.drawing.DrawingManager({
    drawingMode: null,
    drawingControl: false,
    polygonOptions: {
      strokeColor: "#666666",
      strokeWeight: 2,
      fillColor: "#000000",
      fillOpacity: 0.1,
      zIndex: 1,
      editable: true,
      clickable: false,
    },
  });

  drawingManager.setMap(map);

  const listener = google.maps.event.addListener(drawingManager, "polygoncomplete", (poly) => {
    poly.setMap(null);
    drawingManager.setDrawingMode(null);

    onPolygonComplete({ isRadius: false, polygon: convertMvcArray(poly.getPath()) });
  });

  addListener(listener);

  return drawingManager;
};

const convertMvcArray = (array) => {
  const converted = [];
  array.forEach((ele, _) => {
    converted.push({ lat: ele.lat(), lng: ele.lng() });
  });

  return converted;
};

const zoomies = (map) => {
  const zoomDiv = document.createElement("div");

  zoomDiv.style.display = "flex";
  zoomDiv.style.flexDirection = "column";
  zoomDiv.style.border = "1px solid #c2c2c2";
  zoomDiv.style.borderRadius = "4px";
  zoomDiv.style.backgroundColor = "#fff";
  zoomDiv.style.color = "#666666";
  zoomDiv.style.cursor = "pointer";
  zoomDiv.style.marginRight = "12px";
  zoomDiv.style.marginTop = "18px";

  const plus = makeSvg(PlusPath, "Zoom In", true);
  const minus = makeSvg(MinusPath, "Zoom Out");

  zoomDiv.appendChild(plus);
  zoomDiv.appendChild(minus);

  plus.addEventListener("click", () => {
    map.setZoom(map.zoom + 1);
  });

  minus.addEventListener("click", () => {
    map.setZoom(map.zoom - 1);
  });

  return zoomDiv;
};

const makeSvg = (path, title, hasBorder = false) => {
  const div = document.createElement("div");
  div.title = title;
  div.classList.add("zoom-button");
  div.style.padding = "10px";
  div.style.height = "36px"; // Getting a random 2~px addition to height from i don't know where otherwise
  if (hasBorder) {
    div.style.borderBottom = "1px solid #c2c2c2";
  }

  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  const svgPath = document.createElementNS("http://www.w3.org/2000/svg", "path");

  svgPath.setAttributeNS(null, "d", path);
  svg.style.overflow = "visible !important";
  svg.style.width = "16px";
  svg.style.height = "16px";
  svg.setAttributeNS(null, "viewBox", "0 0 16 16");
  svg.setAttributeNS(null, "fill", "currentColor");
  svg.appendChild(svgPath);
  div.appendChild(svg);

  return div;
};

const PlusPath =
  "M16 8a1.23 1.23 0 0 1-1.231 1.231H9.231v5.538a1.23 1.23 0 1 1-2.462 0V9.231H1.231a1.23 1.23 0 1 1 0-2.461h5.538V1.232a1.23 1.23 0 1 1 2.462 0V6.77h5.538C15.45 6.77 16 7.32 16 8z";
const MinusPath = "M13.5 9h-11a1 1 0 1 1 0-2h11a1 1 0 1 1 0 2z";

const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PropertyMarker = ({ price, onClick, isSelected }) => {
  const clicks = (e) => {
    e.preventDefault();

    onClick();
  };

  const selectedStyling = isSelected
    ? "tw-border-theme-primary tw-text-theme-primary before:tw-border-t-theme-primary"
    : "tw-border-gray-30 tw-text-gray-75 before:tw-border-t-gray-30";

  const stateStyles = `
    before:tw-content-[''] before:tw-block before:tw-absolute before:tw-h-0 before:tw-w-0 before:tw-border-solid before:tw-border-transparent
    after:tw-content-[''] after:tw-block after:tw-absolute after:tw-h-0 after:tw-w-0 after:tw-border-solid after:tw-border-transparent
    before:tw-top-full before:tw-border-[7px]
    after:tw-top-[95%] after:tw-border-[7px] after:tw-border-t-white
    hover:tw-border-theme-primary hover:tw-text-theme-primary hover:tw-bg-theme-primary-disabled
    hover:before:tw-border-t-theme-primary hover:after:tw-border-t-theme-primary-disabled
    active:tw-border-theme-primary active:tw-text-white active:tw-bg-theme-primary
    active:before:tw-border-t-theme-primary active:after:tw-border-t-theme-primary
  `;

  return (
    <div
      onClick={clicks}
      className={`tw-relative tw-flex tw-cursor-pointer tw-justify-center tw-bg-white tw-px-8px tw-py-3px tw-items-center tw-rounded-666px tw-border-1px tw-border-solid ${stateStyles} ${selectedStyling}`}
    >
      <span className="tw-text-14px tw-font-sans tw-font-semibold">{formatSimplePrice(price) || "N/A"}</span>
    </div>
  );
};
