import axios from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import { getPostJsonHeaders } from "@shared/axiosHelpers";
import formatPhoneNumber from "@shared/FormatPhoneNumber";
import { toTitleCase } from "@shared/Utilities";
import { formatNumber, formatPrice } from "../../SearchListings/components/helpers";
import { formatCallDuration } from "../../PhoneSystem/TrackingNumbers/helpers";
import { makeDateRangeFromPhrase } from "../../Reports/components/Leaderboards/helpers";

export const usePropertySearch = (locationQuery, filters, setIsFirstLoad, includeDummyData = false) => {
  const [properties, setProperties] = useState([]);
  const [radius, setRadius] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (!locationQuery) {
      setProperties([]);
      return;
    }

    let isCancelled = false;
    setIsFirstLoad(false);
    setIsSearching(true);

    const abortController = new AbortController();

    getProperties(locationQuery, filters, includeDummyData, abortController)
      .then((result) => {
        if (!isCancelled) {
          setProperties(result.properties);
          setIsSearching(false);
          setRadius(result.radius);
        }
      })
      .catch((e) => {
        if (!isCancelled) {
          setIsSearching(false);
        }
      });

    return () => {
      isCancelled = true;
      setIsSearching(false);
      abortController?.abort();
    };
  }, [locationQuery, filters]);

  return { properties, radius, isSearching };
};

export const addLead = async (prospect, property, agentId) => {
  const lead = {
    first_name: prospect.firstName,
    last_name: prospect.lastName,
    primary_agent_id: agentId,
    intent: "seller",
    status: "new",
    source: "Expired",
    stage: "New lead",
    type_name: "lead",
    street_address: property.streetAddress,
    city: property.city,
    locality: property.state,
    postal_code: property.postalCode,
    phone: prospect.phone,
    is_lead: true,
  };

  // TODO: Consider adding `slug` to the POST /api/v3/leads response. Any reason it wouldn't be there already / external concerns?
  const createResponse = await axios.post("/api/v3/leads", lead, getPostJsonHeaders());
  const { data } = await axios.get(
    `/api/v4/person_detail/show/${createResponse.data.lead.person_id}`,
    getPostJsonHeaders(),
  );

  return data.data.attributes.slug;
};

export const getSavedSearches = async (userId, searchType) => {
  const response = await axios.get(
    `/users/${userId}/opportunities_saved_searches?search_type=${searchType.toLowerCase()}`,
  );

  return response.data?.saved_searches;
};

export const saveSearch = async (userId, name, search, searchType) => {
  const data = { saved_search: { name, search_type: searchType.toLowerCase(), search } };

  await axios.post(`/users/${userId}/opportunities_saved_searches`, data, getPostJsonHeaders());
};

export const deleteSearch = async (userId, searchId) => {
  await axios.delete(`/users/${userId}/opportunities_saved_searches/${searchId}`, getPostJsonHeaders());
};

const getProperties = async (location, filters, includeDummyData, abortController) => {
  if (!location) {
    return [];
  }

  const query = Object.entries(filters).reduce((acc, [key, val]) => {
    if (val) {
      acc[key] = val;
    }

    return acc;
  }, {});

  if (location.isRadius) {
    query.lat = location.location.lat;
    query.lng = location.location.lng;
  } else {
    query.polygon = location.polygon.map(({ lat, lng }) => ({ latitude: lat, longitude: lng }));
  }

  if (filters.communicationRange) {
    const { from, to } = makeDateRangeFromPhrase(new Date().getFullYear(), filters.communicationRange);
    query.commsFrom = from;
    query.commsTo = to;
  }

  const options = {
    ...getPostJsonHeaders(),
    signal: abortController?.signal,
  };

  const results = await axios.post("/opportunities/search", query, options);

  return {
    properties: results.data?.properties.map((p) => formatProperty(p, includeDummyData)) || [],
    radius: results.data?.radius || 0,
  };
};

const formatProperty = (property, includeDummyData) => {
  const fullAddress = `${property.streetAddress}, ${property.city}, ${property.state} ${property.postalCode}`;
  const streetViewLink = property.streetAddress ? `https://www.google.com/maps/place/${fullAddress}` : "";
  const zillowLink = property.streetAddress
    ? `https://www.zillow.com/homes/${fullAddress.replace(" ", "-")}`
    : "";

  // LV only shows 3 prospects at most, so we make sure we do too
  const prospects =
    property.prospects?.slice(0, 3).map((p, i) => ({
      ...p,
      name: toTitleCase(p.name), // These come in as all caps
      firstName: toTitleCase(p.firstName),
      lastName: toTitleCase(p.lastName),
      phoneFormatted: formatPhoneNumber(p.phone),
      isRecommended: i === 0,
    })) || [];

  if (includeDummyData && prospects.length) {
    prospects[0] = FakeProspect;
  }

  const callHistory =
    property.callHistory?.map((call) => ({
      ...call,
      phoneDisplay: formatPhoneNumber(call.phone),
      timestampDisplay: format(new Date(call.timestamp), "P"),
      durationDisplay: formatCallDuration(call.duration),
      name: toTitleCase(call.name),
    })) || [];

  return {
    ...property,
    statusDateDisplay: format(new Date(property.statusDate), "P"),
    recommendedProspect: prospects?.[0] || {},
    listPriceFormatted: formatPrice(property.listPrice),
    sqftFormatted: formatNumber(property.sqft),
    fullAddress,
    streetViewLink,
    zillowLink,
    prospects,
    callHistory,
  };
};

const FakeProspect = {
  name: "Matt Garay",
  firstName: "Matt",
  lastName: "Garay",
  phone: "+18056260399",
  isDnc: false,
  isLead: false,
  isMyPondLead: false,
  isMyLead: false,
  phoneFormatted: "+1 (805) 626-0399",
  isRecommended: true,
};
