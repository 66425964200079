import { endOfMonth, endOfYear, format, startOfMonth, startOfYear, sub } from "date-fns";
import { useMemo, useState } from "react";

const TIME_FRAME_OPTIONS = [
  { label: "All Time", value: "all_time" },
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Last 7 days",
    value: "last_7_days",
  },
  {
    label: "Last 14 days",
    value: "last_14_days",
  },
  {
    label: "This Month",
    value: "this_month",
  },
  {
    label: "Last Month",
    value: "last_month",
  },
  {
    label: "This Year",
    value: "this_year",
  },
  {
    label: "Last Year",
    value: "last_year",
  },
];

const useTimeFrameOptions = () => {
  const [timeFrame, setTimeFrame] = useState(TIME_FRAME_OPTIONS[6]);

  const dateRange = useMemo(() => {
    switch (timeFrame.value) {
      case "today":
        return {
          startAt: format(new Date(), "yyyy-MM-dd"),
          endAt: format(new Date(), "yyyy-MM-dd"),
        };
      case "last_7_days":
        return {
          startAt: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
          endAt: format(new Date(), "yyyy-MM-dd"),
        };
      case "last_14_days":
        return {
          startAt: format(sub(new Date(), { days: 14 }), "yyyy-MM-dd"),
          endAt: format(new Date(), "yyyy-MM-dd"),
        };
      case "this_month":
        return {
          startAt: format(startOfMonth(new Date()), "yyyy-MM-dd"),
          endAt: format(new Date(), "yyyy-MM-dd"),
        };
      case "last_month":
        return {
          startAt: format(startOfMonth(sub(new Date(), { months: 1 })), "yyyy-MM-dd"),
          endAt: format(endOfMonth(sub(new Date(), { months: 1 })), "yyyy-MM-dd"),
        };
      case "this_year":
        return {
          startAt: format(startOfYear(new Date()), "yyyy-MM-dd"),
          endAt: format(endOfYear(new Date()), "yyyy-MM-dd"),
        };
      case "last_year":
        return {
          startAt: format(startOfYear(sub(new Date(), { years: 1 })), "yyyy-MM-dd"),
          endAt: format(endOfYear(sub(new Date(), { years: 1 })), "yyyy-MM-dd"),
        };
      default:
        return {};
    }
  }, [timeFrame]);

  return {
    timeFrameOptions: TIME_FRAME_OPTIONS,
    timeFrame,
    setTimeFrame,
    dateRange,
  };
};

export default useTimeFrameOptions;
