/* eslint-disable dot-notation */
import { uniq } from "lodash";
import { tryCatchHandlr } from "../../../../shared/Utilities";
import {
  getAllDetailsFromApi,
  putDetailToApi,
  deleteListingImageApi,
  getPropertyTypesFromApi,
  getSourcesFromApi,
  putRefDetailtoApi,
  importDetailToApi,
  updatePropertyDetailApi,
  getSelectedListingDataFromApi,
  replacePhotoToApi,
} from "../api/apiWrappers";
import {
  saveAllDetails,
  saveDetail,
  saveLinks,
  deleteImg,
  setAccountSources,
  setAccountPropertyTypes,
  showDetailsFailure,
  clearDetailsFailure,
  setRefTitle,
  setRefStatus,
  setTransactionAmount,
  setReferralFee,
  setRefPartnerDetailsTeam,
  setRefPartnerDetailsBrokerage,
  setClientDetailsFinancing,
  updatePropertyDetail,
} from "./creators";
import { editReferralDesiredAddress } from "../../../Header/actions/creators";
import { adaptGetAllDetails, adaptDetailsPayloadToApi, adaptError } from "../api/apiAdapters";

export const getAllDetailsAsThunk = (uuid) => async (dispatch) => {
  const req = getAllDetailsFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(
      showDetailsFailure({
        ...adaptError(err),
        title: "Error - Failed to get details",
      }),
    );
    return;
  }

  const details = adaptGetAllDetails(res.data);
  dispatch(saveAllDetails(details, uuid));
};

export const getSourcesAsThunk = (uuid) => async (dispatch) => {
  const req = getSourcesFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(
      showDetailsFailure({
        ...adaptError(err),
        title: "Error - Failed to get sources",
      }),
    );
    return;
  }

  dispatch(setAccountSources(res.data));
};

export const getPropertyTypesAsThunk = (uuid) => async (dispatch) => {
  const req = getPropertyTypesFromApi(uuid);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(
      showDetailsFailure({
        ...adaptError(err),
        title: "Error - Failed to get property types",
      }),
    );
    return;
  }

  dispatch(setAccountPropertyTypes(res.data));
};

export const importDetailAsThunk = (uuid, payload, setshowAlert) => async (dispatch) => {
  const req = importDetailToApi(uuid, { import: true, listing: payload });

  const [, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(
      showDetailsFailure({
        ...adaptError(err),
        title: "Error - Failed to import transaction",
      }),
    );
    return;
  }
  setshowAlert(true);
  // To hide alert after 5 sec
  setTimeout(() => {
    setshowAlert(false);
  }, 5000);
  dispatch(getAllDetailsAsThunk(uuid));
};

export const putDetailAsThunk = (payload) => async (dispatch) => {
  const req = putDetailToApi(adaptDetailsPayloadToApi(payload));
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(
      showDetailsFailure({
        ...adaptError(err),
        title: "Error - Failed to update transaction",
      }),
    );
    return;
  }

  const { key, group, stateKey } = payload;
  // Dealing with data transformations, as there is tight coupling in state management
  // between the server-provided data and the client state. However, the keys expected
  // by the write APIs don't always match it, both due to inherent differences and
  // adapter changing key names.
  const usedKey = (stateKey || key).toLowerCase();

  const details = adaptGetAllDetails(res.data);

  dispatch(saveDetail(usedKey, details[group][usedKey], group));
};

export const clearDetailsFailureAsThunk = () => async (dispatch) => {
  dispatch(clearDetailsFailure());
};

export const putLinkAsThunk = (payload) => async (dispatch) => {
  const req = putDetailToApi(payload);

  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(
      showDetailsFailure({
        ...adaptError(err),
        title: "Error - Failed to add link",
      }),
    );
    return;
  }

  const details = adaptGetAllDetails(res.data);

  dispatch(saveLinks(details.links));
};

export const replacePhotoAsThunk = (uuid, payload) => async (dispatch) => {
  const req = replacePhotoToApi(uuid, { listing: payload });

  const [, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(
      showDetailsFailure({
        ...adaptError(err),
        title: "Error - Failed to relace photos",
      }),
    );
    return;
  }

  dispatch(getAllDetailsAsThunk(uuid));
};

export const deleteListingImageAsThunk = (payload) => async (dispatch) => {
  if (!payload.id || !payload.deleteUrl) {
    return;
  }
  const req = deleteListingImageApi(payload.deleteUrl);

  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    dispatch(
      showDetailsFailure({
        ...adaptError(err),
        title: "Error - Failed to delete image",
      }),
    );
    return;
  }
  if (res) {
    dispatch(deleteImg(payload.id));
  }
};

export const deleteAllSelectedImages = (listingImages) => (dispatch) => {
  if (!listingImages) {
    return;
  }

  let errors = [];

  listingImages
    .filter((image) => image.checked === true)
    .forEach(async (image, index, images) => {
      const req = deleteListingImageApi(image.deleteUrl);

      const [res, err] = await tryCatchHandlr(req);

      if (err) {
        // Use `uniq` to avoid duplicate error messages
        errors = uniq([...errors, adaptError(err).errors]);
      } else if (res) {
        dispatch(deleteImg(image.id));
      }
      // Check if the forEach has finished before showing errors, if any
      const finished = index + 1 === images.length;
      if (finished && errors.length > 0) {
        dispatch(
          showDetailsFailure({
            errors,
            title: "Error - Failed to delete images",
          }),
        );
      }
    });
};

export const getSelectedListingDataAsThunk = (term, setListingDetails) => async () => {
  const req = getSelectedListingDataFromApi(term);
  const [res, err] = await tryCatchHandlr(req);

  if (err) {
    // eslint-disable-next-line no-alert
    alert("Error - Failed to get import listing details");
    return;
  }

  if (res.data) {
    const listingData = {
      streetAddress: res.data?.streetAddress,
      postalCode: res.data?.postalCode,
      price: res.data?.price,
      mlsNumber: res.data?.mlsNum,
      beds: res.data?.bedrooms,
      baths: res.data?.bathsTotalDecimal,
      sqFt: res.data?.squareFeet,
      city: res.data?.city,
      state: res.data?.state,
      county: res.data?.county,
      property_type: res.data?.propertyType,
      neighborhood: res.data?.neighborhood,
      year_built: res.data?.year,
      parking: res.data?.parkingSpaces,
      foundation: res.data?.foundation,
      photosAvailable: res.data?.photosAvailable,
      photos: res.data?.photos,
      description: res.data?.remarks,
      blossorId: res.data?.blossorId,
    };
    setListingDetails(listingData);
  }
};

const putRefDetail = (creator, key) => (uuid, val) => async (dispatch) => {
  dispatch(creator(val));

  const req = putRefDetailtoApi(uuid, { listing: { [key]: val } });
  const [, err] = await tryCatchHandlr(req);

  // eslint-disable-next-line no-alert
  if (err) alert("Error in updating transaction.");
};

export const putRefTitle = putRefDetail(setRefTitle, "title");

export const putRefStatus = putRefDetail(setRefStatus, "status");

export const putRefTransactionAmt = putRefDetail(setTransactionAmount, "transaction_amount");

export const putRefFinancial = putRefDetail(setClientDetailsFinancing, "financing");

export const putReferralFee = putRefDetail(setReferralFee, "referral_fee");

export const putRefTeam = putRefDetail(setRefPartnerDetailsTeam, "team");

export const putRefBrokerage = putRefDetail(setRefPartnerDetailsBrokerage, "brokerage");

export const putRefDesiredLocations = putRefDetail(editReferralDesiredAddress, "desired_locations");

// This deletes || edits propertyDetail. Usually like to seperate concerns per thunk but delete || edit are from PUT request > details_controller, seperated by _destroy attr.
export const updatePropertyDetailAsThunk = (payload) => async (dispatch) => {
  dispatch(updatePropertyDetail(payload));

  const req = updatePropertyDetailApi(payload);
  const [, err] = await tryCatchHandlr(req);

  // eslint-disable-next-line no-alert
  if (err) alert("Error in updating transaction.");
};
