import React, { useState } from 'react';
import Modal from '@shared/v2/Modal';
import ConferenceCallForm from './ConferenceCallForm';

const ConferenceModal = ({ closeCallModal, dialerServiceUrl, conferenceUuid, dialerServiceToken, startCall, show }) => {
  const [hideheader, setHideHeader] = useState(false);

  return (
    <Modal
      className="tw-flex tw-justify-center tw-items-center"
      show={show}
      contentClassName="tw-m-auto tw-flex tw-flex-col tw-gap-[32px] tw-w-[657px] tw-max-h-[700px] tw-p-[32px] tw-overflow-hidden"
      closeOnClickOutside={false}
      onHide={() => {
        setHideHeader(false);
        closeCallModal();
      }}>
      <Modal.Header
        className={`tw-flex tw-justify-between ${hideheader ? 'tw-hidden' : ''}`}
        title='Brivity Dialer'
        wrapperClassName='tw-w-full'
        closeButton />
      <Modal.Body>
        <ConferenceCallForm
          closeCallModal={closeCallModal}
          dialerServiceUrl={dialerServiceUrl}
          conferenceUuid={conferenceUuid}
          dialerServiceToken={dialerServiceToken}
          startCall={startCall}
          setHideHeader={setHideHeader}
        />
      </Modal.Body>
    </Modal>
  )
};

export default ConferenceModal;
