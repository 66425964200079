import React, { useEffect, useState, useRef } from "react";
import { arrayOf, shape } from "prop-types";
import moment from "moment";
import { produce } from "immer";
import { useClickOutside } from "@shared/hookHelpers";
import FieldLabel from "@shared/v2/FieldLabel";
import InlineEditingInput from "@shared/v2/InlineEditingInput";
import Tooltip from "@shared/v2/Tooltip";
import Dropdown from "@shared/v2/Dropdown";
import { CalendarDaysSolidV6, PenSolidV6 } from "@shared/v2/Icomoon";
import PillButton from "../../Transactions/components/shared/PillButton";
import { defaultTask } from "../../Tasks/components/TaskForm";
import { TimeFrameDateSelector } from "../../TransactionDetailsPage/Header/ReferralHeader/TimeFrameDateSelector/TimeFrameDateSelector";

const adjustToWeekday = (date) => {
  const adjustedDate = moment(date);

  if (adjustedDate.day() === 6) { // Saturday
    adjustedDate.add(2, 'days'); // Push to Monday
  } else if (adjustedDate.day() === 0) { // Sunday
    adjustedDate.add(1, 'days'); // Push to Monday
  }

  return adjustedDate;
}

const DEFAULT_STATE = {
  ...defaultTask,
  name: 'Follow Up',
  type: 'Call',
  assigned_to_user_id: null,
  due_on: moment().format("YYYY-MM-DD"),
  priority: 3
};

const DATE_HASH = {
  Today: moment(),
  Tomorrow: moment().add(1, 'days'),
  '7 Days': moment().add(7, 'days'),
  '30 Days': moment().add(30, 'days'),
  '90 Days': moment().add(90, 'days'),
}

const ADJUSTED_DATE_SET = new Set(['30 Days', '90 Days']);

const CustomPillButton = ({ onClick, isActive, children, className }) => (
  <PillButton
    onClick={onClick}
    className={`tw-border-1px tw-border-solid tw-border-gray-15 tw-text-gray-75 ${isActive ? "tw-text-white tw-bg-theme-button-primary !tw-border-[0px]" : "tw-bg-transparent"
      } ${className}`}
  >
    {children}
  </PillButton>
);

const AddTaskPane = ({ agents, initialUserUuid, initialAgentValue, setState }) => {
  const [task, setTask] = useState(DEFAULT_STATE);
  const [dateType, setDateType] = useState('Today');
  const [showDateSelector, setShowDateSelector] = useState(false);


  useEffect(() => {
    const DEFAULT_ST = {
      ...DEFAULT_STATE,
      assigned_to_user_id: initialUserUuid
    }
    setTask(DEFAULT_ST)
    setState(DEFAULT_ST)
    return () => {
      setTask(DEFAULT_ST);
      setState(null);
    }
  }, []);

  const handleChange = (key, value) => {
    setTask(prev => {
      const task = produce(prev, draft => {
        draft[key] = value;
      })

      setState(task);
      return task;
    })
  };

  const ref = useRef(null);
  useClickOutside(ref, () => {
    handleChange('due_on', moment().format("YYYY-MM-DD"));
    setShowDateSelector(false);
  });

  return (
    <div className="tw-p-16px tw-border-1px tw-border-solid tw-border-gray-10 tw-rounded-6px">
      <FieldLabel className="tw-mb-8px" label="Task" />
      <InlineEditingInput
        onChange={(txt) => {
          handleChange('name', txt.trim().length > 0 ? txt.trim() : 'Follow Up Call');
        }}
        validateInput={(newValue) => ({ isValid: newValue.trim().length <= 40, newValue })}
        displaySpanClassName='tw-text-18d tw-text-gray-50'
        icon={<PenSolidV6 size="m" className="tw-text-gray-50 group-hover:tw-text-gray-99" />}
        value={task.name}
        iconAlwaysVisible />
      <div className="tw-flex tw-flex-col tw-gap-24px tw-mt-24px">
        <div className="tw-flex tw-justify-between">
          <div>
            <FieldLabel className="tw-mb-8px" label="Type" />
            <div className="tw-flex tw-gap-4px">
              <CustomPillButton
                onClick={() => handleChange('type', 'Call')}
                isActive={task.type === 'Call'}
                className="tw-w-[90px]"
              >
                Call
              </CustomPillButton>
              <CustomPillButton
                onClick={() => handleChange('type', 'To-Do')}
                isActive={task.type === 'To-Do'}
                className="tw-w-[90px]"
              >
                To-Do
              </CustomPillButton>
            </div>
          </div>
          <div className="tw-w-[286px]">
            <FieldLabel className="tw-mb-8px" label="Assign To" />
            <Dropdown options={agents} onChange={(v) => handleChange('assigned_to_user_id', v.value)} value={agents.find(agent => agent.value === task.assigned_to_user_id) || initialAgentValue} />
          </div>
        </div>
        <div>
          <FieldLabel className="tw-mb-8px" label="Date" isRequired />
          <div className="tw-flex tw-justify-between">
            {['Today', 'Tomorrow', '7 Days', '30 Days', '90 Days', 'Custom'].map((label) => {
              if (label === 'Custom') {
                return (
                  <CustomPillButton
                    key={label}
                    onClick={() => {
                      setDateType(label);
                      setShowDateSelector(true);
                    }}
                    isActive={dateType === label}
                    className="tw-w-[90px]"
                  >
                    <>
                      <span>{dateType === 'Custom' && task.due_on ? moment(task.due_on).format('MM/DD/YY') : 'Custom'}</span>
                      <CalendarDaysSolidV6 size="m" className="tw-ml-4px" />
                    </>
                  </CustomPillButton>
                )
              }

              const momentDate = ADJUSTED_DATE_SET.has(label) ? adjustToWeekday(DATE_HASH[label]) : moment(DATE_HASH[label]);

              return (
                <Tooltip
                  content={momentDate.format('dddd, MM/DD/YY')}
                  trigger={
                    <CustomPillButton
                      key={label}
                      onClick={() => {
                        setDateType(label);
                        handleChange('due_on', momentDate.format("YYYY-MM-DD"));
                      }}
                      isActive={dateType === label}
                      className="tw-w-[90px]"
                    >
                      {label}
                    </CustomPillButton>
                  }
                  placement='top'
                />
              );
            })}
          </div>
          <TimeFrameDateSelector
            containerClassName="tw-h-[0px]"
            subContainerClassName="tw-right-[-10px] tw-bottom-[-80px]"
            clearBtnClassName="tw-hidden"
            refOuter={ref}
            dateSelectorOnChangeHandlr={(dateObj) => {
              handleChange('due_on', moment(dateObj.date, "MM/DD/YYYY").format("YYYY-MM-DD"));
            }}
            minDate={moment().format('MM/DD/YYYY')}
            dateSelectorValue={moment(task.due_on, "YYYY-MM-DD").format("MM/DD/YYYY")}
            submitHandlr={() => setShowDateSelector(false)}
            show={showDateSelector} />
        </div>
        <div>
          <FieldLabel className="tw-mb-8px" label="Priority" isRequired />
          <div className="tw-flex tw-justify-between">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((priority) => (
              <CustomPillButton
                key={priority}
                onClick={() => handleChange('priority', priority)}
                isActive={task.priority === priority}
                className="tw-w-[58px]"
              >
                {priority}
              </CustomPillButton>
            ))}
          </div>
          <div className="tw-flex tw-justify-between tw-text-12d tw-text-gray-50">
            <span>Highest</span>
            <span>Lowest</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTaskPane;

AddTaskPane.propTypes = {
  agents: arrayOf(shape({})),
};

AddTaskPane.defaultProps = {
  agents: [],
};