import React, { useState } from "react";
import { format, parse, set } from "date-fns";
import axios from "axios";
import Button from "@shared/v2/Button";
import Modal from "@shared/v2/Modal";
import Tooltip from "@shared/v2/Tooltip";
import IconButton from "@shared/v2/IconButton";
import { EllipsisSolidV6 } from "@shared/v2/Icomoon";
import Error from "@shared/Error";
import DatePicker from "@shared/v2/DatePicker";
import Dropdown from "@shared/v2/Dropdown";
import TextInput from "@shared/v2/TextInput";
import AiAssistant from "@shared/AiAssistant";
import { parseISO } from "date-fns";

const INTERACTION_TYPE_OPTIONS = [
  { value: "other", label: "Other" },
  { value: "pop_by", label: "Pop By" },
  { value: "mail", label: "Mail" },
  { value: "social_media", label: "Social Media" },
];

const OtherInteract = ({ otherInteract, otherInteract: { person, personAvatar } }) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState(null);
  const [interaction, setInteraction] = useState(otherInteract.interaction);

  const saveOther = () => {
    setLoading(true);
    axios
      .post(`/people/${interaction.person_id}/interactions`, {
        interaction,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then(() => {
        setLoading(false);
        setIsOpen(false);
        window.location.reload();
      })
      .catch((err) => setErrors(err.response.data?.content));
  };

  const setValue = (name, value) =>
    setInteraction((oldInteraction) => ({ ...oldInteraction, [name]: value }));

  return (
    <div className="modal-container">
      <Tooltip
        placement="right"
        trigger={
          <IconButton aria-label="other interaction" onClick={() => setIsOpen(true)}>
            <EllipsisSolidV6 />
          </IconButton>
        }
        content="Other"
      />

      <Modal
        id="other-interaction-modal"
        className="tw-flex tw-items-center tw-justify-center"
        contentClassName="tw-max-w-[700px] tw-w-[100vw] tw-flex tw-flex-col tw-gap-[32px]"
        closeOnClickOutside={false}
        show={isOpen}
        onHide={() => setIsOpen(false)}
      >
        <Modal.Header title="Interaction" closeButton={!loading} />
        <Modal.Body className="tw-flex tw-flex-col tw-gap-[24px]">
          <div className="contact-card">
            <span className="tooltipper circled user-image default-avatar">{personAvatar}</span>
            <p style={{ fontSize: "16px", fontWeight: "600", color: "#666666" }}>
              {person.first_name} {person.last_name}
            </p>
            <div style={{ fontSize: "14px", color: "#B3B3B3" }} className="overflow">
              {person.main_email}
            </div>
          </div>
          {errors && <Error errorMessage={errors} />}
          <div className="tw-flex tw-gap-[12px]">
            <DatePicker
              containerClassName="tw-flex-1"
              inputProps={{ label: "Date" }}
              value={format(
                typeof interaction.interacted_at === "object"
                  ? interaction.interacted_at
                  : parseISO(interaction.interacted_at),
                "MM/dd/yyyy",
              )}
              onChange={(date) => {
                const d = new Date();
                setValue(
                  "interacted_at",
                  set(parse(date, "MM/dd/yyyy", d), {
                    hours: d.getHours(),
                    minutes: d.getMinutes(),
                  }).toISOString(),
                );
              }}
            />
            <Dropdown
              containerClassName="tw-flex-1"
              label="Type"
              options={INTERACTION_TYPE_OPTIONS}
              value={INTERACTION_TYPE_OPTIONS.find((o) => o.value === interaction.interaction_type)}
              onChange={(o) => setValue("interaction_type", o.value)}
            />
          </div>
          <TextInput
            multiline
            label={
              <div className="tw-w-full tw-flex tw-justify-between tw-items-end">
                <span>Notes</span>
                <AiAssistant
                  messageType="Generic"
                  contactUuid={interaction.person_uuid}
                  onInsertClick={(text) => setValue("comment", text)}
                />
              </div>
            }
            value={interaction.comment}
            rows={5}
            onChange={(e) => setValue("comment", e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-justify-between">
          <Button size="medium" disabled={loading} schema="tertiary" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button size="medium" isLoading={loading} onClick={saveOther}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OtherInteract;
