import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Overlay } from "react-bootstrap";
import RemoveablePill from "../../../shared/RemoveablePill";
import DropDownSelectOption from "../../../shared/DropDownSelectOption";
import { useClickOutside } from "../../../shared/hookHelpers";
import { useOnAgentSelect, useOnPondSelect, onSelectFn } from "./hookHelpers";
import { useGetAgentState } from "../../../shared/agentOrPondHookHelpers";
import "./styles.scss";

const PondOrAgentFilter = ({
  enableLeadPonds = false,
  selectedAgentsFilters = [],
  selectedPondsFilters = [],
}) => {
  const target = useRef(null);
  const [state, setState, handleChange] = useGetAgentState(enableLeadPonds);
  const [selectedAgents, onAgentSelect] = useOnAgentSelect(selectedAgentsFilters);
  const [selectedPonds, onPondSelect] = useOnPondSelect(selectedPondsFilters);

  useClickOutside(target, () => setState({ ...state, show: false }));

  const { filter, filteredAssignableAgents, filteredAssignablePonds } = state;

  return (
    <div className="tw-relative lp-filter">
      <div
        className="tw-flex tw-items-center tw-justify-end tw-w-full tw-h-[32px] tw-py-[6px] tw-px-[4px] tw-bg-white tw-border-solid tw-border tw-border-gray-15 tw-relative"
        ref={target}
      >
        <input
          className="lp-filter-input tw-w-full tw-h-full tw-left-0 tw-top-0 tw-absolute tw-border-0 tw-bg-transparent tw-outline-blue-100"
          onChange={handleChange}
          onFocus={() => setState((s) => ({ ...s, show: true }))}
          placeholder={enableLeadPonds ? "Select primary agents or lead ponds" : "Search to select"}
          value={filter}
        />
        <i className="fa fa-caret-down tw-text-[15px] tw-text-black tw-pr-[6px] tw-pl-1" />
      </div>
      <Overlay
        show={state.show}
        onHide={() => setState((s) => ({ ...s, show: false }))}
        placement="bottom"
        container={target.current}
        target={target.current}
      >
        <div className="lp-filter-opts tw-bg-white tw-z-40 tw-shadow-modal tw-absolute tw-max-h-[350px] tw-w-full tw-overflow-y-auto">
          {filteredAssignablePonds.length > 0 && (
            <p className="tw-text-[12px] tw-py-1 tw-px-2 tw-bg-neutral-gray-10">Pond</p>
          )}
          {filteredAssignablePonds.map((pond) => {
            const isSelected = selectedPonds.filter((p) => p.id === pond.id).length === 1;
            return (
              <DropDownSelectOption
                key={pond.id}
                isSelected={isSelected}
                showCheckIcon
                option={{ ...pond }}
                handleSelect={() => {
                  onPondSelect([...onSelectFn(selectedPonds, pond, isSelected)]);
                }}
              />
            );
          })}

          {filteredAssignableAgents.length > 0 && (
            <p className="tw-text-[12px] tw-py-1 tw-px-2 tw-bg-neutral-gray-10">Team</p>
          )}
          {filteredAssignableAgents.map((agent) => {
            const isSelected = selectedAgents.filter((p) => p.id === agent.id).length === 1;
            return (
              <DropDownSelectOption
                key={agent.id}
                isSelected={isSelected}
                showCheckIcon
                option={{
                  ...agent,
                  imageSrc: agent.image_src,
                }}
                imgSizeClass="force-img-sm"
                handleSelect={() => {
                  onAgentSelect([...onSelectFn(selectedAgents, agent, isSelected)]);
                }}
              />
            );
          })}
        </div>
      </Overlay>

      {selectedPonds?.map((p) => (
        <span key={p.id}>
          <input type="hidden" name="leads_filter[selected_lead_ponds][names][]" value={p.name} />
          <input type="hidden" name="leads_filter[selected_lead_ponds][ids][]" value={p.id} />
          <RemoveablePill
            label={`Lead Pond: ${p.name}`}
            onClick={() => onPondSelect([...onSelectFn(selectedPonds, p, 1)])}
          />
        </span>
      ))}

      {selectedAgents?.map((p) => (
        <span key={p.id}>
          <input type="hidden" name="leads_filter[selected_primary_agents][]" value={p.id} />
          <RemoveablePill
            label={`Agent: ${p.name}`}
            onClick={() => onAgentSelect([...onSelectFn(selectedAgents, p, 1)])}
          />
        </span>
      ))}
    </div>
  );
};

PondOrAgentFilter.propTypes = {
  enableLeadPonds: PropTypes.bool,
  selectedAgentsFilters: PropTypes.arrayOf(PropTypes.shape()),
  selectedPondsFilters: PropTypes.arrayOf(PropTypes.shape()),
};

PondOrAgentFilter.defaultProps = {
  enableLeadPonds: false,
  selectedAgentsFilters: [],
  selectedPondsFilters: [],
};

export default PondOrAgentFilter;
