import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import Button from "@shared/v2/Button/Button";
import Upload from "@shared/v2/Icomoon/Icons/Upload";
import DatePicker from "@shared/v2/DatePicker/DatePicker";
import { formatFileSize } from "../../actions/helper";

const UploadAgreement = ({
  handleDocumentUpload,
  uploadedFile,
  handleFileDrop,
  setExpirationDate,
  setSignedDate,
  expirationDate,
  selectedAgreementName,
  signedDate,
}) => {
  const hiddenUploadInput = useRef(null);
  const uploadingDocument = useSelector((state) => state.personDetail.uploadingDocument);

  return (
    <div>
      <div className="tw-pb-[9px] tw-pt-[25px] tw-text-14px tw-leading-[20px] tw-font-semibold tw-text-gray-50">
        <span className="tw-capitalize"> {`Upload ${selectedAgreementName} Agreement (Optional)`}</span>
      </div>
      <div
        className={`${uploadedFile ? "tw-border-solid tw-bg-[#FFFFFF] tw-border-neutral-gray-10" : "tw-border-gray-15 tw-border-dashed tw-bg-[#FAFAFA]"} tw-w-[552px] tw-mb-24px !tw-py-[13px] tw-px-15px tw-border tw-rounded-4px`}
        data-cy="agreement-documents-upload"
        onDrop={handleFileDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <input
          id="documentUploadInput"
          type="file"
          ref={hiddenUploadInput}
          onChange={handleDocumentUpload}
          style={{ display: "none" }}
        />
        <div className="!tw-flex tw-grid tw-grid-cols-6 tw-gap-[16px] tw-justify-between tw-h-[33px]">
          <div className="tw-col-start-1 tw-col-span-1">
            <div className="tw-flex">
              <FontAwesomeIcon
                className={`${uploadedFile ? "tw-text-teal" : "tw-text-neutral-gray-30"} tw-h-[32px] tw-w-[24px] tw-pr-[16px] tw-mb-12px tw-text-24px `}
                icon={faFileAlt}
              />
              <div className="tw-m-0 tw-mb-4px tw-text-12d tw-font-normal tw-text-gray-75">
                {uploadedFile ? uploadedFile.name : "Drop your file here, or select from computer"}
                <br />
                <span className="tw-m-0 tw-mb-4px tw-text-12d tw-font-normal tw-text-gray-50">
                  {uploadedFile ? formatFileSize(uploadedFile.size) : "(File Size Limit 15MB)"}
                </span>
              </div>
            </div>
          </div>
          <div className="tw-col-end-8 tw-col-span-1 tw-pt-[4px]">
            <label htmlFor="documentUploadInput">
              <Button
                size="small"
                schema="secondary"
                disabled={uploadingDocument}
                onClick={() => hiddenUploadInput.current?.click()}
              >
                {!uploadedFile && <Upload size="m" />}
                {uploadingDocument ? (
                  <span>
                    {uploadedFile ? "replace   " : "   upload   "} &nbsp;
                    <i className="fa fa-pulse fa-spinner" />
                  </span>
                ) : uploadedFile ? (
                  "replace"
                ) : (
                  "   upload"
                )}
              </Button>
            </label>
          </div>
        </div>
      </div>
      <div className="tw-pb-24px tw-grid tw-grid-cols-2 tw-gap-[24px] tw-justify-between">
        <div>
          <div className="tw-pb-[9px] tw-capitalize tw-text-14px tw-leading-[20px] tw-font-semibold tw-text-gray-50">
            Agreement Signed Date
          </div>
          <DatePicker
            inputProps={{ placeholder: "MM/DD/YYYY" }}
            onChange={(value) => setSignedDate(moment(value).format("YYYY-MM-DD"))}
            value={signedDate}
          />
        </div>

        <div>
          <div className="tw-pb-[9px] tw-capitalize tw-text-14px tw-leading-[20px] tw-font-semibold tw-text-gray-50">
            Agreement Expiration Date
          </div>
          <DatePicker
            inputProps={{ placeholder: "MM/DD/YYYY" }}
            onChange={(value) => setExpirationDate(moment(value).format("YYYY-MM-DD"))}
            value={expirationDate}
          />
        </div>
      </div>
    </div>
  );
};

UploadAgreement.propTypes = {
  handleDocumentUpload: PropTypes.func,
  handleFileDrop: PropTypes.func,
  setExpirationDate: PropTypes.func,
  setSignedDate: PropTypes.func,
  signedDate: PropTypes.string,
  expirationDate: PropTypes.string,
  selectedAgreementName: PropTypes.string,
  uploadedFile: PropTypes.shape({ name: PropTypes.string, size: PropTypes.number }),
};
UploadAgreement.defaultProps = {
  handleDocumentUpload: () => {},
  handleFileDrop: () => {},
  uploadedFile: {},
  setExpirationDate: () => {},
  setSignedDate: () => {},
  expirationDate: "",
  signedDate: "",
  selectedAgreementName: "Buyer",
};
export default UploadAgreement;
