import React, { useState } from "react";
import SmsStatus from "./SmsStatus";
import MmsMediaModal from "../../../shared/MmsMediaModal";
import DncFlag from "@shared/Sms/DncFlag";
import formatPhoneNumber from "@shared/FormatPhoneNumber";

const SmsHeadline = ({ event }) => {
  const { agent_person: agentPerson, object_attributes: objectAttributes, recipient: lead } = event;
  const agentName = getContactName(agentPerson.first_name, agentPerson.last_name);
  const leadName = getContactName(lead.first_name, lead.last_name);

  const smsDirection = objectAttributes.interaction_type;
  const from = smsDirection === "sms_out" ? agentName : leadName;
  const to = smsDirection === "sms_out" ? leadName : agentName;

  return (
    <div>
      <span className="bold-detail">{from}</span>
      {" texted "}
      <span className="bold-detail">{to}</span>
    </div>
  );
};

const DncSmsHeadline = ({ event }) => {
  const { agent_person: agentPerson, object_attributes: objectAttributes, recipient: lead, meta } = event;
  const agentName = getContactName(agentPerson.first_name, agentPerson.last_name);
  const leadName = getContactName(lead.first_name, lead.last_name);
  const leadPhone = formatPhoneNumber(meta.to_phone_number);
  const autoPlanName = meta.auto_plan_name;

  const smsDirection = objectAttributes.interaction_type;
  const from = smsDirection === "sms_out" ? agentName : leadName;
  const to = smsDirection === "sms_out" ? leadPhone : agentName;

  return (
    <p className="tw-m-0 tw-items-center tw-flex tw-flex-wrap">
      <span className="bold-detail from">{from}</span>
      &nbsp;texted&nbsp;
      <span className="bold-detail to">{to}</span>
      &nbsp;
      <span className="tw-inline-block">
        <DncFlag show={true} />
      </span>
      &nbsp;from&nbsp;
      <span className="bold-detail auto">{autoPlanName}</span>
    </p>
  );
};

const SmsMedia = ({ media }) => {
  if (["image/jpeg", "image/jpg", "image/gif", "image/png"].indexOf(media.content_type) >= 0) {
    return <img src={media.url} className="tw-rounded-lg tw-max-h-96 tw-max-w-96 tw-h-auto tw-w-auto" />;
  } else {
    return <div>Unsupported media type</div>;
  }
};

const getContactName = (firstName, lastName) => {
  let contactName = "No Name";

  if (firstName || lastName) {
    contactName = `${firstName} ${lastName}`.trim();
  }

  return contactName;
};

const PublicActivitySmsEvent = ({ personId, event, newDetailsPage, setInteractionTab }) => {
  const [state, setState] = useState({
    mmsMediaModalOpen: false,
    messageForMmsMediaModal: null,
    mediaIndexForMmsMediaModal: null,
  });

  const openMmsMediaModal = (message, index) => {
    setState({
      messageForMmsMediaModal: message,
      mediaIndexForMmsMediaModal: index,
      mmsMediaModalOpen: true,
    });
  };

  const closeMmsMediaModal = () => {
    setState({
      mmsMediaModalOpen: false,
      messageForMmsMediaModal: null,
      mediaIndexForMmsMediaModal: null,
    });
  };

  const handleSmsEventClick = () => {
    if (event.mms_media) {
      return;
    } else if (newDetailsPage) {
      setInteractionTab(3);
      window.scrollTo(0, 0);
    } else {
      Brivity.SMSInteraction.showModal(personId, null);
    }
  };

  const useDnc = event.meta?.dnc && event.meta?.auto_plan_name;
  const Headline = useDnc ? DncSmsHeadline : SmsHeadline;
  return (
    <div>
      <div className="timeline-event" onClick={handleSmsEventClick}>
        <div className="row">
          <div className="col-xs-1">
            <i className="fa fa-commenting-o timeline-icon-circle" />
          </div>
          <div className="col-xs-8">
            <Headline event={event} />
            <div>
              <div className="tw-whitespace-pre-wrap timeline-description-text">
                {event.object_attributes.comment}
              </div>
              {useDnc && (
                <p className="tw-m-0 tw-text-12d tw-text-neutral-gray-50 tw-space-x-1 tw-mt-4px">
                  <span className="tw-italic">
                    At the time of texting, this number was on the Do Not Call Registry.
                  </span>
                  <a
                    className="tw-font-semibold tw-text-neutral-gray-50 visited:tw-text-neutral-gray-50 active:tw-text-neutral-gray-50 hover:tw-text-neutral-gray-50"
                    href="https://www.brivityknowledge.com/v1/docs/do-not-call-registry-warning"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </p>
              )}
            </div>
            <div className="tw-flex tw-flex-wrap tw-gap-[12px]">
              {event.mms_media?.map((media, index) => (
                <div
                  key={index}
                  onClick={(e) => {
                    openMmsMediaModal(event, index);
                  }}
                >
                  <SmsMedia media={media} />
                </div>
              ))}
            </div>
          </div>
          <div className="col-xs-3 time-ago">
            <span>{event.interacted_at_absolute}</span>
            <br />
            <SmsStatus
              deliveryStatus={event.delivery_status}
              errorCode={event.non_delivery_reasons?.find((r) => r.error_code)?.error_code}
              failedRecipients={event.non_delivery_reasons?.map((reason) => reason.recipient) || []}
            />
          </div>
        </div>
      </div>
      {state.mmsMediaModalOpen && (
        <MmsMediaModal
          modalOpen={state.mmsMediaModalOpen}
          closeModal={closeMmsMediaModal}
          message={state.messageForMmsMediaModal}
          mediaIndex={state.mediaIndexForMmsMediaModal}
        />
      )}
    </div>
  );
};

export default PublicActivitySmsEvent;
