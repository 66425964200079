import React, { useMemo } from "react";

import Checkbox from "@shared/v2/Checkbox";
import { PhoneSolidV6, ThumbsUpSolidV6, UserPlusSolidV6 } from "@shared/v2/Icomoon";
import IconButton from "@shared/v2/IconButton";
import Dnc from "./Dnc";
import Tooltip from "@shared/v2/Tooltip";

const PropertyCard = ({ property, isSelected, select, status, addProspectAsContact, callProspect }) => {
  const prospects = useMemo(() => property.prospects?.slice(0, 3), [property]);

  return (
    <div className="tw-flex tw-flex-col tw-w-[279px] tw-h-[284px] tw-cursor-default tw-border-[1.5px] tw-border-solid tw-border-theme-primary tw-rounded-8px tw-bg-white tw-p-16px tw-whitespace-nowrap tw-font-sans">
      <div className="tw-flex tw-flex-row tw-gap-6px tw-mb-8px">
        <Checkbox checked={isSelected} onChange={select} />
        <span className="tw-text-gray-50 tw-font-bold tw-text-11d">
          {status}: {property.statusDateDisplay}
        </span>
      </div>
      <span className="tw-max-w-[220px] tw-overflow-hidden tw-overflow-ellipsis tw-text-18px tw-text-gray-75 tw-mb-6px">
        {property.streetAddress}
      </span>
      <div className="tw-flex tw-flex-row tw-text-gray-50 tw-gap-8px">
        <div className="tw-flex tw-flex-col tw-w-full tw-gap-2px">
          <span className="tw-text-10px tw-font-semibold">Price</span>
          <span className="tw-text-12px">{property.listPriceFormatted || "--"}</span>
        </div>
        <div className="tw-flex tw-flex-col tw-w-full tw-gap-2px">
          <span className="tw-text-10px tw-font-semibold">DOM</span>
          <span className="tw-text-12px">{property.daysOnMarket || "--"}</span>
        </div>
      </div>
      <hr className="!tw-my-12px tw-w-full" />
      {prospects.length > 0 ? (
        <div className="tw-flex tw-flex-col tw-gap-12px">
          {prospects.map((prospect, i) => (
            <Prospect
              key={`${property.id}-${i}`}
              prospect={prospect}
              addProspectAsContact={() => addProspectAsContact(property, prospect)}
              callProspect={() => callProspect({ property, prospect })}
            />
          ))}
        </div>
      ) : (
        <div className="tw-flex tw-items-center tw-justify-center tw-flex-1">
          <span className="tw-max-w-[125px] tw-text-center tw-text-wrap tw-text-14px tw-italic tw-text-gray-75">
            No prospects found for this property
          </span>
        </div>
      )}
    </div>
  );
};

export default PropertyCard;

const Prospect = ({ prospect, addProspectAsContact, callProspect }) => {
  return (
    <div className="tw-flex tw-flex-row tw-justify-between">
      <div className="tw-flex tw-flex-col tw-gap-2px">
        <div className="tw-flex tw-flex-row tw-gap-4px tw-items-center">
          <span
            className={`tw-text-14px tw-max-w-[170px] tw-overflow-hidden tw-overflow-ellipsis tw-text-gray-75 ${prospect.name ? "" : "tw-italic"}`}
          >
            {prospect.name || "No Name"}
          </span>
          {prospect.isRecommended && <ThumbsUpSolidV6 />}
        </div>
        <div className="tw-flex tw-flex-row tw-gap-4px">
          <span className="tw-text-12px tw-text-gray-75">{prospect.phoneFormatted || "--"}</span>
          <Dnc show={prospect.isDnc} />
        </div>
      </div>
      <div className="tw-flex tw-flex-row tw-gap-8px tw-items-center">
        <Tooltip
          trigger={
            <IconButton schema="primary" size="small" onClick={callProspect} disabled={!prospect.phone}>
              <PhoneSolidV6 size="s" />
            </IconButton>
          }
          placement="right"
          content={<span>Call prospect</span>}
        />
        <Tooltip
          trigger={
            <IconButton schema="secondary" size="small" onClick={addProspectAsContact}>
              <UserPlusSolidV6 size="s" />
            </IconButton>
          }
          placement="right"
          content={<span>Add as lead</span>}
        />
      </div>
    </div>
  );
};
