import React from "react";

import Toggle from "@shared/v2/Toggle";
import RemovableBubble from "@shared/RemovableBubble";
import { formatPrice } from "../../SearchListings/components/helpers";
import TextButton from "@shared/v2/TextButton";
import {
  communicationDateOptions,
  communicationOptions,
  getDefaultFilters,
  toggleArrayValue,
  setField,
} from "./filters";

const Options = ({
  location,
  setLocation,
  filters,
  setFilters,
  isMapVisible,
  setIsMapVisible,
  saveSearch,
}) => {
  return (
    <div className="tw-flex tw-justify-between tw-gap-8px">
      <Bubbles
        location={location}
        setLocation={setLocation}
        filters={filters}
        setFilters={setFilters}
        saveSearch={saveSearch}
      />
      <div className="tw-h-[26px] tw-flex tw-flex-row tw-items-start">
        <Toggle
          text="Map"
          checked={isMapVisible}
          onChange={() => setIsMapVisible(!isMapVisible)}
          labelClassName="tw-text-12px tw-text-gray-50 !tw-m-0"
        />
      </div>
    </div>
  );
};

export default Options;

const Bubbles = ({ location, setLocation, filters, setFilters, saveSearch }) => {
  const removePairedFilters = (min, max) => {
    setFilters(setField(setField(filters, max, ""), min, ""));
  };

  const removePropertyType = (propertyType) => {
    setFilters(toggleArrayValue(filters, "propertyTypes", propertyType));
  };

  const removeCommunicationsFilters = () => {
    setFilters(setField(setField(filters, "communication", null), "communicationRange", null));
  };

  const removeExpiredDateFilters = () => {
    setFilters(setField(setField(filters, "expiredFrom", ""), "expiredTo", ""));
  };

  const clearAll = () => {
    setFilters(getDefaultFilters());
    setLocation(null);
  };

  const createBubbles = () => {
    const bubbles = [];
    if (location) {
      bubbles.push(
        <RemovableBubble
          key="location-query"
          value={location.isRadius ? location.name : "Polygon Search"}
          remove={() => setLocation(null)}
          includeMargins={false}
        />,
      );
    }

    FilterDataForBubbles.forEach(({ desc, min, max, asPrice }) => {
      const text = createFilterText(desc, filters[min], filters[max], asPrice);
      if (text) {
        bubbles.push(
          <RemovableBubble
            value={text}
            key={text}
            remove={() => removePairedFilters(min, max)}
            includeMargins={false}
          />,
        );
      }
    });

    if (filters.propertyTypes?.length) {
      filters.propertyTypes.forEach((pt) => {
        bubbles.push(
          <RemovableBubble
            value={pt}
            key={pt}
            remove={() => removePropertyType(pt)}
            includeMargins={false}
          />,
        );
      });
    }

    if (filters.communication) {
      const firstBit = `Communication: ${communicationOptions.find((c) => c.value === filters.communication).label}`;
      const secondBit = ` ${filters.communicationRange ? communicationDateOptions.find((c) => c.value === filters.communicationRange).label.toLowerCase() : ""}`;

      bubbles.push(
        <RemovableBubble
          value={firstBit + secondBit}
          key={firstBit}
          remove={() => removeCommunicationsFilters()}
          includeMargins={false}
        />,
      );
    }

    if (filters.expiredFrom || filters.expiredTo) {
      let expiredText = "Expiration Date: ";
      if (filters.expiredFrom && filters.expiredTo) {
        expiredText += `${filters.expiredFrom} - ${filters.expiredTo}`;
      } else if (filters.expiredFrom) {
        expiredText += `After ${filters.expiredFrom}`;
      } else {
        expiredText += `Before ${filters.expiredTo}`;
      }

      bubbles.push(
        <RemovableBubble
          value={expiredText}
          key={expiredText}
          remove={() => removeExpiredDateFilters()}
          includeMargins={false}
        />,
      );
    }

    if (bubbles.length) {
      bubbles.push(
        <TextButton
          key="save-search"
          size="small"
          schema="default"
          children="Save Search"
          onClick={saveSearch}
        />,
        <TextButton
          key="clear-all"
          size="small"
          schema="remove"
          children="Clear All"
          onClick={() => clearAll()}
        />,
      );
    }

    return bubbles;
  };

  return <div className="tw-flex tw-flex-row tw-gap-4px tw-flex-wrap">{createBubbles()}</div>;
};

const FilterDataForBubbles = [
  {
    desc: "Price",
    min: "priceMin",
    max: "priceMax",
    asPrice: true,
  },
  {
    desc: "Square Feet",
    min: "sqftMin",
    max: "sqftMax",
  },
  {
    desc: "Beds",
    min: "bedsMin",
    max: "bedsMax",
  },
  {
    desc: "Baths",
    min: "bathsMin",
    max: "bathsMax",
  },
  {
    desc: "Lot Size",
    min: "lotSizeMin",
    max: "lotSizeMax",
  },
];

const createFilterText = (desc, min, max, asPrice = false) => {
  if (!min && !max) {
    return;
  }

  const minFormatted = asPrice ? formatPrice(min) : min;
  const maxFormatted = asPrice ? formatPrice(max) : max;

  return minFormatted === maxFormatted
    ? `${desc}: ${minFormatted}`
    : `${desc}: ${minFormatted || "No Min"} - ${maxFormatted || "No Max"}`;
};
