import React, { useEffect, useState } from "react";
import css from "./person-detail-styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import api from "@shared/phoneDncStatusApi";
import axios from "axios";
import TextButton from "@shared/v2/TextButton";
import EditableElementWithAdd from "./EditableElementWithAdd";
import SocialIcon from "./SocialIcon";
import EditSocialMediaModal from "./modals/EditSocialMediaModal";
import EditAddressesModal from "./modals/EditAddressesModal";
import Loading from "../../Goals/components/Loader";
import {
  closeEditAddressModal,
  closeEditSocialMediaModal,
  openEditAddressModal,
  openEditSocialMediaModal,
  updatePersonData,
} from "../actions/personDetailActionCreators";
import CollapsibleElement from "./CollapsibleElement";
import { useCurrentUser } from "../../reducers/layoutReducer/selectors";
import IconButton from "../../shared/v2/IconButton";
import { PencilSolidV6, PlusSolidV6 } from "../../shared/v2/Icomoon";
import { tryCatchHandlr } from "@shared/Utilities";
import { getPostJsonHeaders } from "@shared/axiosHelpers";
import { useMessagingServiceUrl } from "../../reducers/messagingReducer/selectors";
import { getMessagingApi } from "../../Inbox/helpers/messagingApi";

const phoneNumbers = (person) =>
  (person.included || [])
    .filter((a) => a.type === "phone_detail" && a.id !== null)
    .map((phone) => phone.attributes?.value);

const getAddressAttributes = (person) =>
  (person.included || [])
    .filter((a) => a.type === "address" && a.id !== null)
    .map((address) => ({ ...address.attributes, id: address.id }));

const getListingsForPersonAddresses = async (personId) => {
  const [res, err] = await tryCatchHandlr(
    axios.get(`/geo_addresses/listings_for_person?person_id=${personId}`, getPostJsonHeaders()),
  );

  return res?.data || {};
};

const getListings = async (blossorIds) => {
  if (!blossorIds.length) {
    return [];
  }

  const data = {
    search_request: {
      blossorIds,
      statuses: [1, 2, 3, 4, 5],
      queryOptions: {
        sort: [{ field: "listDate", order: "desc" }],
      },
    },
  };

  const [res, err] = await tryCatchHandlr(axios.post("/listings_api/search", data, getPostJsonHeaders()));

  return res?.data || [];
};

const mapAddress = (addressAttributes, addressListingStatuses) => ({
  addressType: addressAttributes["address_type"],
  streetAddress: addressAttributes["street_address"],
  cityStateZip: `${addressAttributes["city"]}, ${addressAttributes["locality"]} ${addressAttributes["postal_code"]}`,
  status: addressListingStatuses[addressAttributes.id]?.status || "",
  updated: addressListingStatuses[addressAttributes.id]?.updated || "",
});

const mapAddressListingStatus = (blossorIdsForAddress, listings) => {
  if (!blossorIdsForAddress?.length) {
    return {};
  }

  let listing;
  let hasBadMatches = false;
  for (const l of listings) {
    // If an address has any N/A standard addresses associated with it, we don't want to trust that data for now.
    if (l.standardAddress?.startsWith("N/A,")) {
      hasBadMatches = true;
      break;
    }

    if (!listing && blossorIdsForAddress.includes(l.blossorId)) {
      listing = l;
    }
  }

  if (hasBadMatches || !listing) {
    return {};
  }

  return {
    updated: listing.lastStatusChangeDate || listing.updateDate,
    status: listing.standardStatus === "Expired" ? "Withdrawn" : listing.standardStatus,
  };
};

const ContactInfo = () => {
  const dispatch = useDispatch();
  const person = useSelector((state) => state.personDetail.person);
  const editAddressModalOpen = useSelector((state) => state.personDetail.editAddressModalOpen);
  const editSocialMediaModalOpen = useSelector((state) => state.personDetail.editSocialMediaModalOpen);
  const messagingServiceUrl = useMessagingServiceUrl();
  const messagingApi = getMessagingApi(messagingServiceUrl);
  const currentUser = useCurrentUser();
  const [dncStatuses, setDncStatuses] = useState({});
  const [conversationCounts, setConversationCounts] = useState({});
  const [addressListingStatuses, setAddressListingStatuses] = useState({});

  const disabled =
    person.data?.attributes.type_name === "team" &&
    currentUser.person.userType === "Agent" &&
    String(currentUser.person.id) !== person.data?.id;

  const getPhoneStatuses = () =>
    api
      .index([phoneNumbers(person)])
      .then(({ data }) => setDncStatuses(data))
      .catch(() => "dnc lookup failed");

  const getConversationCounts = () =>
    Promise.all(
      phoneNumbers(person).map((phoneNumber) =>
        messagingApi
          .get(
            `/Conversation/SmsAndGroupSmsCountForPersonAddressPair/${person.data?.attributes.uuid}/${phoneNumber}`,
          )
          .then((res) => ({ [phoneNumber]: res.data.total }))
          .catch(() => ({ [phoneNumber]: 0 })),
      ),
    )
      .then((res) => res.reduce((acc, val) => ({ ...acc, ...val }), {}))
      .then(setConversationCounts);

  useEffect(() => {
    getPhoneStatuses();
  }, [phoneNumbers(person).join("")]);

  useEffect(() => {
    if (!person?.data) {
      return;
    }

    const getAddresses = async () => {
      const listingsByAddressId = await getListingsForPersonAddresses(person.data.id);

      const blossorIds = Object.values(listingsByAddressId).reduce((acc, cur) => {
        return [...acc, ...cur];
      }, []);
      const listings = await getListings(blossorIds);

      const mapped = Object.entries(listingsByAddressId).reduce((acc, [addressId, blossorIdsForAddress]) => {
        acc[addressId] = mapAddressListingStatus(blossorIdsForAddress, listings);

        return acc;
      }, {});

      setAddressListingStatuses(mapped);
    };

    getAddresses();
    getConversationCounts();
  }, [person]);

  const detailsObjects = (type) =>
    person.included?.filter((a) => a.type === type && a.id !== null).map((a) => a.attributes) || [];

  const isDncPhone = (element) => {
    const phoneNumber = element.value;
    return api.isDnc(phoneNumber, dncStatuses);
  };

  const emailDetailsElement = () => {
    if (detailsObjects("email_detail")?.length > 0) {
      return _.sortBy(detailsObjects("email_detail"), (e) => e.position, ["desc"]).map((email, index) => {
        return (
          <EditableElementWithAdd
            key={`email${index}`}
            disabled={disabled}
            person={person}
            type="email"
            id={parseInt(email.id)}
            updatePersonData={(data) => dispatch(updatePersonData(data))}
          />
        );
      });
    } else {
      return (
        <EditableElementWithAdd
          person={person}
          disabled={disabled}
          type="email"
          updatePersonData={(data) => dispatch(updatePersonData(data))}
        />
      );
    }
  };

  const phoneDetailsElement = () => {
    if (detailsObjects("phone_detail").length > 0) {
      return detailsObjects("phone_detail").map((phone, index) => {
        return (
          <EditableElementWithAdd
            key={`phone${index}-${phone.value}`}
            disabled={disabled}
            person={person}
            type="phone"
            id={parseInt(phone.id)}
            updatePersonData={(data) => dispatch(updatePersonData(data))}
            isDnc={isDncPhone(phone)}
            conversationCount={conversationCounts[phone.value]}
          />
        );
      });
    } else {
      return (
        <EditableElementWithAdd
          disabled={disabled}
          person={person}
          type="phone"
          updatePersonData={(data) => dispatch(updatePersonData(data))}
          isDnc={false}
        />
      );
    }
  };

  const addressDetailsElement = () => {
    const addressDetails = getAddressAttributes(person).map((address) =>
      mapAddress(address, addressListingStatuses),
    );

    if (addressDetails.length > 0) {
      return (
        <div className="tw-py-[8px] tw-px-[20px] hover:tw-bg-gray-5 tw-group" data-cy="address_pdp_card">
          <div className="tw-flex tw-justify-between tw-items-center">
            <div className={css.brivityPersonContactLabel}>{`Addresses (${addressDetails.length})`}</div>
            {!disabled && (
              <div className="tw-flex tw-gap-[4px] tw-invisible group-hover:tw-visible">
                <IconButton
                  size="small"
                  onClick={() => dispatch(openEditAddressModal())}
                  data-cy="open_edit_address_modal_btn"
                >
                  <PencilSolidV6 />
                </IconButton>
                <IconButton size="small" onClick={() => dispatch(openEditAddressModal())}>
                  <PlusSolidV6 />
                </IconButton>
              </div>
            )}
          </div>
          {addressDetails.map((address) => (
            <AddressDetails address={address} />
          ))}
        </div>
      );
    } else {
      return (
        <div className="tw-py-[8px] tw-px-[20px] hover:tw-bg-gray-5">
          <div className={css.brivityPersonContactLabel}>Address</div>
          <TextButton
            schema="sentence"
            onClick={() => dispatch(openEditAddressModal())}
            disabled={disabled}
            data-cy="pdp_add_address"
          >
            Add Address
          </TextButton>
        </div>
      );
    }
  };

  const socialMediaDetailsElement = () => {
    return (
      <div className="tw-py-[8px] tw-px-[20px] tw-group hover:tw-bg-gray-5">
        <div className="tw-flex tw-gap-[8px] tw-justify-between tw-items-center">
          <div className={css.brivityPersonDetailsLabel}>Social Media</div>
          {!disabled && (
            <IconButton
              size="small"
              className="tw-invisible group-hover:tw-visible"
              onClick={() => dispatch(openEditSocialMediaModal())}
            >
              <PencilSolidV6 />
            </IconButton>
          )}
        </div>
        {person.data && (
          <div className="tw-flex tw-gap-[24px] tw-w-full tw-flex-wrap">
            <SocialIcon link={person.data.attributes.facebook.value} icon="fa fa-facebook" />
            <SocialIcon link={person.data.attributes.twitter.value} icon="fab fa6-x-twitter" />
            <SocialIcon link={person.data.attributes.linkedin.value} icon="fa fa-linkedin" />
            <SocialIcon link={person.data.attributes.instagram.value} icon="fa fa-instagram" />
            <SocialIcon link={person.data.attributes.youtube.value} icon="fab fa6-youtube" />
            <SocialIcon link={person.data.attributes.tiktok.value} icon="fab fa6-tiktok" />
            <SocialIcon link={person.data.attributes.pinterest.value} icon="fab fa6-pinterest" />
          </div>
        )}
        {editSocialMediaModalOpen && (
          <EditSocialMediaModal closeEditSocialMediaModal={() => dispatch(closeEditSocialMediaModal())} />
        )}
      </div>
    );
  };

  return (
    <CollapsibleElement title="Contact Info" id="pdp-contact-info">
      {!person.data && <Loading />}
      <div id="person-contact-info-card" className="tw-mx-[-20px]">
        {emailDetailsElement()}

        {phoneDetailsElement()}

        {addressDetailsElement()}
        {editAddressModalOpen && (
          <EditAddressesModal
            person={person}
            updatePersonData={(data) => dispatch(updatePersonData(data))}
            closeEditAddressModal={() => dispatch(closeEditAddressModal())}
          />
        )}

        {socialMediaDetailsElement()}
      </div>
    </CollapsibleElement>
  );
};

export default ContactInfo;

const AddressDetails = ({ address }) => {
  const statusColors = getListingStatusColors(address.status);

  return (
    <div key={`ad-${address.streetAddress}`} className="tw-py-[5px]" data-cy="pdp_ind_addy_card">
      <div className="tw-flex tw-flex-row tw-py-[8px] tw-justify-between tw-items-center">
        <span className="tw-text-10px tw-font-semibold tw-text-gray-50 tw-uppercase">
          {address.addressType}
        </span>
        {address.status ? (
          <span
            className={`tw-rounded-lg tw-px-8px tw-py-4px tw-text-12px tw-font-semibold ${statusColors.background} ${statusColors.text}`}
          >
            {address.status}
          </span>
        ) : null}
      </div>
      <div className="tw-flex tw-flex-col tw-text-14px tw-text-nowrap tw-mb-4px">
        <span>{address.streetAddress}</span>
        <span>{address.cityStateZip}</span>
      </div>
      {address.updated ? (
        <span className="tw-text-gray-50 tw-text-12px">
          System notified of status change{" "}
          {new Date(address.updated).toLocaleDateString("en-US", { timeZone: "UTC" })}
        </span>
      ) : null}
    </div>
  );
};

const getListingStatusColors = (status) => {
  switch (status) {
    case "Active":
      return { text: "tw-text-[#36b25f]", background: "tw-bg-[#EDFAF1]" };
    case "Pending":
    case "Active Under Contract":
      return { text: "tw-text-[#e5941b]", background: "tw-bg-[#faf1e6]" };
    case "Closed":
      return { text: "tw-text-[#e5342e]", background: "tw-bg-[#fae6e6]" };
    case "Withdrawn":
    default:
      return { text: "tw-text-[#666666]", background: "tw-bg-[#f5f7f7]" };
  }
};
