import React, { useState } from "react";
import Proptypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import FilterPill from "@shared/v2/FilterPill";
import { useEventListenerAction } from "@shared/hooks/useEventListenerAction";
import { CLEAR_MODAL_FILTER_ID } from "../../shared/constants";

const AgreementStatusSelect = ({ previousSelectedStatus }) => {
  const [selectedStatus, setSelectedStatus] = useState(previousSelectedStatus || []);

  useEventListenerAction(CLEAR_MODAL_FILTER_ID, () => setSelectedStatus([]));

  const handleOnChange = (status) => {
    setSelectedStatus((prevSelectedStatus) => {
      if (prevSelectedStatus.includes(status)) {
        // If status exists, remove it
        return prevSelectedStatus.filter((item) => item !== status);
      }
      // If status doesn't exist, add it
      return [...prevSelectedStatus, status];
    });
  };

  return (
    <Row className="m-b-10">
      <Col xs={12}>
        <label htmlFor="selected_agreement_filters-status">Status</label>
      </Col>

      <Col xs={12} className="tw-ml-[10px]">
        <Row className="tw-gap-4px">
          {selectedStatus.map((status) => (
            <input
              type="hidden"
              key={status}
              id="selected_agreement_filters-status"
              name="leads_filter[selected_agreement_filters][status][]"
              value={status}
            />
          ))}
          <FilterPill
            className="filter-checkbox"
            onClick={() => handleOnChange("active")}
            selected={selectedStatus.includes("active")}
          >
            Active
          </FilterPill>
          <FilterPill
            className="filter-checkbox"
            onClick={() => handleOnChange("completed")}
            selected={selectedStatus.includes("completed")}
          >
            Completed
          </FilterPill>
          <FilterPill
            className="filter-checkbox"
            onClick={() => handleOnChange("expired")}
            selected={selectedStatus.includes("expired")}
          >
            Expired
          </FilterPill>
          <FilterPill
            className="filter-checkbox"
            onClick={() => handleOnChange("canceled")}
            selected={selectedStatus.includes("canceled")}
          >
            Canceled
          </FilterPill>
          <FilterPill
            className="filter-checkbox"
            onClick={() => handleOnChange("none")}
            selected={selectedStatus.includes("none")}
          >
            None
          </FilterPill>
        </Row>
      </Col>
    </Row>
  );
};
AgreementStatusSelect.propTypes = {
  previousSelectedStatus: Proptypes.arrayOf(Proptypes.string),
};
AgreementStatusSelect.defaultProps = {
  previousSelectedStatus: [],
};
export default AgreementStatusSelect;
