import React, { useEffect, useState, useMemo } from "react";
import { add, format, setSeconds } from "date-fns";
import { ArrowUpRightFromSquareSolidV6, PlusSolidV6 } from "@shared/v2/Icomoon";
import Button from "@shared/v2/Button";
import Dropdown from "@shared/v2/Dropdown";
import TextLink from "@shared/v2/TextLink";
import Typography from "@shared/v2/Typography";
import { Section } from "../BusinessDashboard";
import OpenHouseOverview from "./OpenHouseOverview";
import OpenHouseDashboardList from "./OpenHouseDashboardList";
import CustomCheckboxOption from "../../../Dashboard/components/CustomCheckboxOption";
import useTimeFrameOptions from "./hooks/useTimeFrameOptions";
import useOpenHouseDashboardHosts from "./hooks/useOpenHouseDashboardHosts";
import SingleDialerModal from "../../../Dialer/SingleDialerModal";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";
import OpenHouseModal from "./OpenHouseModal";

const generateNewOpenHouse = (currentUser) => {
  const startTime = setSeconds(add(new Date(), { minutes: 15 - (new Date().getMinutes() % 15) }), 0);
  return {
    openHouse: {
      startDate: format(startTime, "yyyy-MM-dd"),
      startTime: format(startTime, "HH:mm:ss"),
      endDate: format(startTime, "yyyy-MM-dd"),
      endTime: format(add(startTime, { hours: 2 }), "HH:mm:ss"),
      hostPersonUuid: currentUser.person.uuid,
      instructions: "",
      isPending: false,
      isActive: false,
    },
    propertyDetails: { country: "US" },
    tags: ["Open House", "openhouseapp", ""],
  };
};

const OpenHouseDashboard = () => {
  const currentUser = useCurrentUser();
  const [openHouse, setOpenHouse] = useState(null);
  const [selectedHosts, setSelectedHosts] = useState([]);
  const { timeFrameOptions, timeFrame, setTimeFrame, dateRange } = useTimeFrameOptions();
  const hosts = useOpenHouseDashboardHosts();
  const modalHosts = useMemo(() => hosts.slice(1), [hosts]);
  const selectedHostsWithoutAll = useMemo(
    () => (selectedHosts[0]?.value === "all" ? hosts.filter((host) => host.value !== "all") : selectedHosts),
    [selectedHosts, hosts],
  );

  useEffect(() => {
    if (selectedHosts.length === 0 && hosts.length > 0) {
      setSelectedHosts([hosts[0]]);
    }
  }, [selectedHosts, hosts]);

  useEffect(() => {
    if (!openHouse) setSelectedHosts((prev) => [...prev]);
  }, [openHouse]);

  return (
    <div className="tw-bg-gray-5 tw-flex tw-p-[16px]">
      <div className="tw-max-w-[1500px] tw-w-full tw-mx-auto tw-flex tw-flex-col tw-gap-[16px]">
        <Section className="!tw-p-[20px]">
          <div className="tw-flex tw-flex-col tw-gap-[16px]">
            <div className="tw-flex tw-flex-wrap tw-justify-between tw-items-center">
              <h2 className="tw-text-28d tw-mr-[8px] tw-m-0">Open House Dashboard</h2>
              <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-[16px]">
                {currentUser.account.isAdmin && (
                  <>
                    <Typography variant="subtitle-m" className="!tw-text-gray-50">
                      Host:
                    </Typography>
                    <Dropdown
                      containerClassName="tw-min-w-[200px]"
                      components={{
                        Option: (props) => (
                          <CustomCheckboxOption
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...props}
                            onClick={(host, checked) => {
                              if (host.value === "all") {
                                setSelectedHosts(checked ? [host] : []);
                              } else {
                                setSelectedHosts((currentHosts) => {
                                  if (checked)
                                    return [...currentHosts.filter((a) => a.value !== "all"), host];
                                  return currentHosts.length > 1
                                    ? currentHosts.filter((a) => a.value !== host.value)
                                    : [hosts[0]];
                                });
                              }
                            }}
                            // eslint-disable-next-line
                            showDivider={props.data.value === "all"}
                          />
                        ),
                      }}
                      placeholder={
                        <span className="tw-text-gray-base">
                          {selectedHosts[0]?.label}
                          {selectedHosts.length > 1 ? ` +${selectedHosts.length - 1}` : ""}
                        </span>
                      }
                      options={hosts}
                      value={selectedHosts}
                      isMulti
                      closeMenuOnSelect={false}
                      isClearable={false}
                      multiShouldRenderBelowSelect={false}
                      controlShouldRenderValue={false}
                      hideSelectedOptions={false}
                    />
                  </>
                )}
                <Dropdown
                  containerClassName="tw-min-w-[160px]"
                  options={timeFrameOptions}
                  value={timeFrame}
                  onChange={setTimeFrame}
                />
                <Button
                  className="tw-inline-flex tw-items-center tw-gap-[4px] tw-whitespace-nowrap"
                  schema="secondary"
                  size="medium"
                  onClick={() => setOpenHouse(generateNewOpenHouse(currentUser))}
                >
                  <PlusSolidV6 /> Open House
                </Button>
              </div>
            </div>
            <span>
              All data gathered from the Open House App.{" "}
              <TextLink href="https://www.brivityknowledge.com/docs/brivity-open-house-app" target="_blank">
                Learn More <ArrowUpRightFromSquareSolidV6 />
              </TextLink>
            </span>
          </div>
        </Section>

        <OpenHouseOverview dateRange={dateRange} selectedHosts={selectedHostsWithoutAll} />

        <OpenHouseDashboardList
          dateRange={dateRange}
          selectedHosts={selectedHostsWithoutAll}
          onEdit={setOpenHouse}
        />

        <OpenHouseModal onClose={() => setOpenHouse(null)} openHouse={openHouse} hosts={modalHosts} />

        <SingleDialerModal />
      </div>
    </div>
  );
};

export default OpenHouseDashboard;
