import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Checkbox from "@shared/v2/Checkbox";
import RingChart from "@shared/v2/RingChart";
import TextButton from "@shared/v2/TextButton";
import Tooltip from "@shared/v2/Tooltip";
import { AngleRightSolidV6, CircleInfoSolidV6 } from "@shared/v2/Icomoon";
import { Section, SectionLabel } from "./Section";
import useSalesFunnel from "./services/useSalesFunnel";
import Loading from "../../../Goals/components/Loader";
import Error from "../DashboardShared/Error";
import goToLeadView from "./helpers/goToLeadView";
import { useTheme } from "../../../reducers/layoutReducer/selectors";

const SetHeldTooltip = () => (
  <>
    <div>Only appointment types which directly drive production are counted towards Set & Held goals:</div>
    <ul className="tw-m-0 tw-p-0 tw-pl-[24px]">
      <li>Buyer Consultation</li>
      <li>Listing Consultation</li>
      <li>Buyer/Listing Consultation</li>
    </ul>
  </>
);

const signedTooltip =
  'Signing appointments completed with an outcome of "Signed - Buyer Agreement", "Signed - Listing Agreement", or "Signed - Both Buyer/Listing"';

const getClassFromValue = (value) => {
  if (value > 999999) return "tw-text-12d";
  if (value > 99999) return "tw-text-14d";
  return "tw-text-18d";
};

const InnerRing = ({ agentId, filters, goal, value, title, dateRange }) => {
  const hasGoal = goal > 0;
  const valueClass = getClassFromValue(value);
  const goalClass = getClassFromValue(goal);
  return (
    <div className="tw-group tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center">
      <div
        className={`tw-flex tw-flex-col tw-gap-[2px] ${hasGoal ? "group-hover:tw-hidden" : ""} tw-text-center`}
      >
        <span className={valueClass}>{value.toLocaleString()}</span>
        <hr className="tw-m-0" />
        {hasGoal && <span className={`${goalClass} tw-text-gray-50`}>{goal.toLocaleString()}</span>}
        {!hasGoal && (
          <TextButton onClick={() => window.open("/settings/goals", "_blank")}>Set Goal</TextButton>
        )}
      </div>
      {hasGoal && (
        <TextButton
          className="tw-hidden group-hover:tw-block"
          onClick={() => goToLeadView({ agentId, filters, title, dateRange })}
        >
          View
        </TextButton>
      )}
    </div>
  );
};

InnerRing.propTypes = {
  agentId: PropTypes.number,
  filters: PropTypes.shape().isRequired,
  goal: PropTypes.number,
  value: PropTypes.number,
  title: PropTypes.string.isRequired,
  dateRange: PropTypes.string.isRequired,
};

InnerRing.defaultProps = {
  agentId: undefined,
  goal: 0,
  value: 0,
};

const Percent = ({ tooltip, value }) => (
  <div className="tw-flex-1 tw-my-[8px] tw-min-w-[40px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-border-solid tw-border-x-0 tw-border-y-[1px] tw-border-gray-5">
    <Tooltip
      trigger={<span className="tw-text-18d tw-text-gray-50 tw-font-bold">{value}</span>}
      content={`${value} ${tooltip}`}
    />
  </div>
);

Percent.propTypes = {
  tooltip: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

const FunnelRing = ({ agentId, data, filters, title, tooltip, dateRange }) => {
  const isPlace = useTheme().name === "place-theme";
  const ringChartData = useMemo(() => {
    const { goal, actual, onTarget } = data;
    if (goal === 0) return [];
    return [
      { value: onTarget / goal, color: isPlace ? "#C8D9FA" : "#BDE7E7" },
      { value: actual / goal, color: isPlace ? "#3270FA" : "#59C4C4" },
    ];
  }, [data, isPlace]);
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-mt-[-20px]">
      <span className="tw-text-14d tw-font-semibold tw-text-gray-50">
        {title}{" "}
        {tooltip && (
          <Tooltip
            placement="right"
            innerClassName="tw-max-w-[240px] !tw-text-left"
            trigger={<CircleInfoSolidV6 />}
            content={tooltip}
          />
        )}
      </span>
      <Tooltip
        className="tw-w-[104px]"
        innerClassName="tw-min-w-[160px]"
        trigger={
          <RingChart data={ringChartData}>
            <InnerRing
              agentId={agentId}
              filters={filters}
              goal={data.goal}
              value={data.actual}
              title={title}
              dateRange={dateRange}
            />
          </RingChart>
        }
        content={
          <div className="tw-flex tw-flex-col tw-gap-[8px]">
            <div className="tw-flex tw-justify-between">
              <span>{title}:</span>
              <span>{data.actual.toLocaleString()}</span>
            </div>
            <div className="tw-flex tw-justify-between">
              <span>On Target:</span>
              <span>{data.onTarget.toLocaleString()}</span>
            </div>
            <div className="tw-flex tw-justify-between">
              <span>Total Goal:</span>
              <span>{data.goal.toLocaleString()}</span>
            </div>
          </div>
        }
      />
    </div>
  );
};

FunnelRing.propTypes = {
  agentId: PropTypes.number,
  data: PropTypes.shape({
    actual: PropTypes.number,
    goal: PropTypes.number,
    onTarget: PropTypes.number,
  }),
  dateRange: PropTypes.string.isRequired,
  filters: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.node,
};

FunnelRing.defaultProps = {
  agentId: undefined,
  data: { actual: 0, goal: 0, onTarget: 0 },
  tooltip: null,
};

const useDateOptions = () => {
  const [dateRange, setDateRange] = useState("this_year");
  const options = useMemo(
    () => [
      { label: "Today", value: "today" },
      { label: "Last 7 Days", value: "last_7_days" },
      { label: "Last 14 Days", value: "last_14_days" },
      { label: "This Month", value: "this_month" },
      { label: "Last Month", value: "last_month" },
      { label: "This Year", value: "this_year" },
      { label: "Last Year", value: "last_year" },
      // { label: "All Time", value: "all_time" },
    ],
    [],
  );
  return { options, dateRange, setDateRange };
};

const SalesFunnel = ({ agentId, agentPersonId, agentUuid }) => {
  const [filters, setFilters] = useState({
    buyer: true,
    seller: true,
    buyerSeller: true,
  });
  const updateFilter = (name) => () => setFilters((prev) => ({ ...prev, [name]: !prev[name] }));
  const { options, dateRange, setDateRange } = useDateOptions();
  const { data, error, loading } = useSalesFunnel({ agentUuid, dateRange, filters });

  return (
    <Section className="tw-gap-[16px]">
      <SectionLabel className="tw-flex tw-flex-wrap tw-justify-between tw-gap-[16px]">
        <div className="tw-flex tw-flex-wrap tw-gap-[16px]">
          <span>Sales Funnel</span>
          <Checkbox label="Buyer" checked={filters.buyer} onChange={updateFilter("buyer")} />
          <Checkbox label="Seller" checked={filters.seller} onChange={updateFilter("seller")} />
          <Checkbox
            label="Buyer/Seller"
            checked={filters.buyerSeller}
            onChange={updateFilter("buyerSeller")}
          />
        </div>
        <div className="tw-flex tw-items-center">
          <select
            className="tw-text-14d tw-font-normal tw-border-0 tw-bg-transparent"
            name="funnel-date"
            onChange={(e) => setDateRange(e.target.value)}
          >
            {options.map(({ label, value }) => (
              <option key={value} value={value} selected={value === dateRange}>
                {label}
              </option>
            ))}
          </select>
          <span className=" tw-h-[80%] tw-ml-[8px] tw-mr-[4px] tw-border-solid tw-border-[1px] tw-border-y-0 tw-border-r-0 tw-border-gray-10" />
          <TextButton
            className="tw-inline-flex tw-items-center tw-gap-[4px]"
            schema="sentence"
            size="medium"
            onClick={() => window.open("/settings/goals", "_blank")}
          >
            Goal Settings <AngleRightSolidV6 />
          </TextButton>
        </div>
      </SectionLabel>
      <div className="tw-overflow-x-auto tw-flex tw-gap-[4px] tw-pt-[44px]">
        <FunnelRing
          filters={filters}
          title="Called"
          data={data.calls}
          dateRange={dateRange}
          agentId={agentId}
        />
        <Percent tooltip="of Called moved to Contacted" value={`${data.callsContactedRatio}%`} />
        <FunnelRing
          filters={filters}
          title="Contacted"
          data={data.contacted}
          dateRange={dateRange}
          agentId={agentId}
        />
        <Percent tooltip="of Contacted moved to Appt. Set" value={`${data.contactedApptSetRatio}%`} />
        <FunnelRing
          filters={filters}
          title="Appt. Set"
          data={data.apptSet}
          tooltip={<SetHeldTooltip />}
          dateRange={dateRange}
          agentId={agentId}
        />
        <Percent tooltip="of Appt. Set moved to Appt. Held" value={`${data.apptSetApptHeldRatio}%`} />
        <FunnelRing
          filters={filters}
          title="Appt. Held"
          data={data.apptHeld}
          tooltip={<SetHeldTooltip />}
          dateRange={dateRange}
          agentId={agentId}
        />
        <Percent tooltip="of Appt. Held moved to Signed" value={`${data.apptHeldApptSignedRatio}%`} />
        <FunnelRing
          filters={filters}
          title="Signed"
          data={data.apptSigned}
          tooltip={signedTooltip}
          dateRange={dateRange}
          agentId={agentId}
        />
        <Percent tooltip="of Signed moved to Pended" value={`${data.apptSignedPendedRatio}%`} />
        <FunnelRing
          filters={filters}
          title="Pended"
          data={data.pended}
          dateRange={dateRange}
          agentId={agentPersonId}
        />
        <Percent tooltip="of Pended moved to Closed" value={`${data.pendedClosedRatio}%`} />
        <FunnelRing
          filters={filters}
          title="Closed"
          data={data.closed}
          dateRange={dateRange}
          agentId={agentPersonId}
        />
      </div>
      {loading && <Loading />}
      <Error show={Boolean(error)} message={error} />
    </Section>
  );
};

SalesFunnel.propTypes = {
  agentUuid: PropTypes.string,
  agentId: PropTypes.number,
  agentPersonId: PropTypes.number,
};

SalesFunnel.defaultProps = {
  agentUuid: undefined,
  agentId: undefined,
  agentPersonId: undefined,
};

export default SalesFunnel;
