import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import { Dropdown } from "../../shared/v1";
import { Input } from "../../shared";
import PlatformCTA from "./PlatformCTA";

const DisabledHistoryFields = () => (
  <div>
    <Col xs={12}>
      <FormGroup id="view-and-favorites-selector">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="brivity-grey-text fa fa-circle-o disabled" />
        <span style={{ marginRight: "16px" }}>Property Views &amp; Favorites</span>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="brivity-grey-text fa fa-circle-o disabled" />
        <span style={{ marginRight: "16px" }}>Views only</span>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="brivity-grey-text fa fa-circle-o disabled" />
        <span>Favorites only</span>
      </FormGroup>
    </Col>
    <PlatformCTA>
      <Col xs={6}>
        <FormGroup>
          <label htmlFor="filters-disabled-history-fields_property-min-price">Min Price</label>
          <Input id="filters-disabled-history-fields_property-min-price" disabled />
        </FormGroup>
      </Col>
    </PlatformCTA>
    <PlatformCTA>
      <Col xs={6}>
        <FormGroup>
          <label htmlFor="filters-disabled-history-fields_property-max-price">Max Price</label>
          <Input id="filters-disabled-history-fields_property-max-price" disabled />
        </FormGroup>
      </Col>
    </PlatformCTA>
    <PlatformCTA>
      <Col xs={6}>
        <FormGroup>
          <Row>
            <Col xs={12}>
              <label htmlFor="filters-disabled-history-fields_property-bedrooms">Bedrooms</label>
            </Col>
            <Col xs={12}>
              <div className="range-dropdowns tw-cursor-not-allowed">
                <Dropdown
                  id="filters-disabled-history-fields_property-bedrooms"
                  placeholder="No Min"
                  isDisabled
                />
                <div className="range-separator" />
                <Dropdown placeholder="No Max" isDisabled />
              </div>
            </Col>
          </Row>
        </FormGroup>
      </Col>
    </PlatformCTA>
    <PlatformCTA>
      <Col xs={6}>
        <FormGroup>
          <Row>
            <Col xs={12}>
              <label htmlFor="filters-disabled-history-fields_property-bathrooms">Bathrooms</label>
            </Col>
            <Col xs={12}>
              <div className="range-dropdowns tw-cursor-not-allowed">
                <Dropdown
                  id="filters-disabled-history-fields_property-bathrooms"
                  placeholder="No Min"
                  isDisabled
                />
                <div className="range-separator" />
                <Dropdown placeholder="No Max" isDisabled />
              </div>
            </Col>
          </Row>
        </FormGroup>
      </Col>
    </PlatformCTA>
    <PlatformCTA>
      <Col xs={12}>
        <FormGroup style={{ position: "relative" }}>
          <label htmlFor="filters-disabled-history-fields_property-location">Location</label>
          <Input id="filters-disabled-history-fields_property-location" disabled />
        </FormGroup>
      </Col>
    </PlatformCTA>
  </div>
);

export default DisabledHistoryFields;
