import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import cards from "../cards.module.css";
import colors from "../colors.module.css";
import ViewAllTasksModal from "../modals/ViewAllTasksModal";
import CardTask from "./CardTask";
import TaskModal from "./TaskModal";
import css from "../person-detail-styles.module.css";
import Delete from "../../../Tasks/components/People/Delete";
import Details from "./Details";
import Reassign from "../../../Tasks/components/People/Reassign";
import Reschedule from "../../../Tasks/components/People/Reschedule";
import * as actions from "../../actions/personDetailActionCreators";
import CollapsibleElement from "../CollapsibleElement";
import Loading from "../../../Goals/components/Loader";
import { useCurrentUser } from "../../../reducers/layoutReducer/selectors";

const initialMassActionModalState = {
  agentsOptions: undefined,
  assignedToUserId: undefined,
  dropdownUpdater: undefined,
  form: undefined,
  reassignTasksHandler: undefined,
  rescheduleDate: undefined,
  submitDisabled: false,
  selectedTasks: undefined,
  showDeleteModal: false,
  showReassignModal: false,
  showRescheduleModal: false,
  tasksUpdated: 0,
};

const CardTasks = ({ allAgents, accountMilestoneNames }) => {
  const dispatch = useDispatch();
  const [state, _setState] = useState({
    ...initialMassActionModalState,
    viewAlltasks: false,
    viewDetails: false,
    selectedTask: undefined,
  });
  const setState = (newState) =>
    _setState((prevState) => ({
      ...prevState,
      ...(typeof newState === "function" ? newState(prevState) : newState),
    }));
  const currentUser = useCurrentUser();
  const person = useSelector((state) => state.personDetail.person);
  const taskModalOpen = useSelector((state) => state.personDetail.taskModalOpen);
  const incompleteTasks = useSelector((state) => state.personDetail.tasks);
  const allTaskCount = useSelector((state) => state.personDetail.allTaskCount);

  const getTasks = (personUuid) => dispatch(actions.getTasks(personUuid));
  const openTaskModal = () => dispatch(actions.openTaskModal());
  const closeTaskModalAction = () => dispatch(actions.closeTaskModal());
  const updateCardTasks = (task) => dispatch(actions.updateCardTasks(task));

  useEffect(() => {
    if (person.data?.attributes.uuid) getTasks(person.data.attributes.uuid);
  }, [person.data?.attributes.uuid]);

  const openTasksModal = () => {
    setState({ viewAlltasks: true });
  };

  const closeViewAllTasksModal = () => {
    setState({ viewAlltasks: false });
  };

  const taskFromViewAll = () => {
    openTaskModal();
  };

  const showTaskDetails = (task) => setState({ viewDetails: true, selectedTask: task });

  const closeTaskDetails = () => setState({ viewDetails: false, selectedTask: undefined });

  const editTask = (task) => {
    setState({ viewDetails: false, selectedTask: task });
    openTaskModal();
  };

  const editAndComplete = (task) => {
    task["state"] = "completed";
    setState({ viewDetails: false, selectedTask: task, task });
    openTaskModal();
  };

  const closeTaskModal = () => {
    setState({ selectedTask: undefined });
    closeTaskModalAction();
  };

  const updateQuickViewTask = (taskToUpdate) => {
    updateCardTasks(taskToUpdate);
  };

  const completeTask = (task) => {
    const formData = Object.assign({}, task);
    axios
      .patch(`/tasks/${task.id}/complete`, {
        task: formData,
        authenticity_token: ReactOnRails.authenticityToken(),
      })
      .then((response) => {
        getTasks(task.taskable_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const completeTaskFromDetails = (task) => {
    closeTaskDetails();
    completeTask(task);
  };

  const editTaskFromViewAll = (task) => {
    setState({ selectedTask: task });
    openTaskModal();
  };

  const showReassignModal = ({ agentsOptions, assignedToUserId, form, selectedTasks }) => {
    setState({
      agentsOptions,
      assignedToUserId,
      form,
      selectedTasks,
      viewAlltasks: false,
      showReassignModal: true,
    });
  };

  const closeReassignModal = () => {
    setState({ ...initialMassActionModalState });
    openTasksModal();
  };

  const reassignAgent = (e) => {
    setState({ assignedToUserId: e.target.value });
  };

  const reassignTasks = () => {
    state.selectedTasks.forEach((task) => {
      task.assigned_to_user_id = state.assignedToUserId;
      if (task.assigned_to_user_id == "anyone") {
        task.assigned_to_user_id = null;
      }
      updateTask(task);
    });
  };

  const showRescheduleModal = ({ selectedTasks }) => {
    setState({
      selectedTasks,
      viewAlltasks: false,
      showRescheduleModal: true,
    });
  };

  const closeRescheduleModal = () => {
    setState({ ...initialMassActionModalState });
    openTasksModal();
  };

  const handleRescheduleDate = (e) => {
    setState({ rescheduleDate: moment(e).format("YYYY-MM-DD") });
  };

  const rescheduleTasks = () => {
    state.selectedTasks.forEach((task) => {
      task.due_on = state.rescheduleDate;
      updateTask(task);
    });
  };

  const updateTask = (task) => {
    const task_path = `/tasks/${task.id}`;
    let formData = { ...task };
    setState({ submitDisabled: true });

    axios
      .patch(task_path, { task: formData, authenticity_token: ReactOnRails.authenticityToken() })
      .then((res) => {
        setState((prevState) => ({ tasksUpdated: prevState.tasksUpdated + 1 }));
      })
      .catch((err) => {
        setState((prevState) => ({ tasksUpdated: prevState.tasksUpdated + 1 }));
        console.log("Task failed to update successfully");
      });
  };

  const showDeleteModal = ({ selectedTasks }) => {
    setState({
      selectedTasks,
      viewAlltasks: false,
      showDeleteModal: true,
    });
  };

  const closeDeleteModal = () => {
    setState({ ...initialMassActionModalState });
    openTasksModal();
  };

  const deleteTasks = () => {
    setState((prevState) => ({ ...prevState, submitDisabled: true }));
    state.selectedTasks.forEach((task) => {
      axios
        .delete(`/tasks/${task.id}`, { params: { authenticity_token: ReactOnRails.authenticityToken() } })
        .then((res) => {
          setState((prevState) => ({ tasksUpdated: prevState.tasksUpdated + 1 }));
        })
        .catch((err) => {
          setState((prevState) => ({ tasksUpdated: prevState.tasksUpdated + 1 }));
          console.log("Couldn't delete task");
        });
    });
  };

  const viewTaskLink = (incompleteTasks, allTaskCount) => {
    const linkText =
      incompleteTasks.length > 0 ? "VIEW ALL" : allTaskCount > 0 ? "VIEW COMPLETED TASKS" : null;

    if (incompleteTasks.length == 0 && allTaskCount == 0) {
      return;
    }

    return (
      <Row>
        <Col xs={12} className="m-t-10">
          <a onClick={openTasksModal} className={`${cards.viewAll} ${colors.brivityBlue}`}>
            {linkText}
          </a>
        </Col>
      </Row>
    );
  };

  const emptyState = (incompleteTasks, allTaskCount) => {
    if (incompleteTasks.length == 0 && allTaskCount == 0) {
      return (
        <div className={css.personDetailEmptyStateText}>
          Create a task for this contact and assign it to yourself or a teammate.
        </div>
      );
    } else if (incompleteTasks.length == 0 && allTaskCount > 0) {
      return <div className={css.personDetailEmptyStateText}>No upcoming tasks.</div>;
    }
  };

  const taskable = {
    task_name: `${person.data?.attributes.first_name} ${person.data?.attributes.last_name}`.trim(),
    uuid: person.data?.attributes.uuid,
    taskable_type: "Person",
  };

  const secondaryModalOpen = Boolean(state.selectedTask) || taskModalOpen;

  return (
    <CollapsibleElement id="pdp-tasks" title="Tasks" buttonText="+ ADD" buttonClickFunction={openTaskModal}>
      {!person.data && <Loading />}
      <div id="person-tasks-card" className="tw-flex tw-flex-col tw-gap-[12px]">
        {emptyState(incompleteTasks, allTaskCount)}
        {incompleteTasks.map((task) => (
          <CardTask
            key={task.id}
            task={task}
            getCardTasks={getTasks}
            showTaskDetails={showTaskDetails}
            completeTask={completeTask}
            editTask={editTask}
          />
        ))}
        {viewTaskLink(incompleteTasks, allTaskCount)}
        {state.viewAlltasks && !secondaryModalOpen && (
          <ViewAllTasksModal
            onClose={closeViewAllTasksModal}
            account={currentUser.account}
            user={currentUser}
            accountMilestoneNames={accountMilestoneNames}
            isAgent={true}
            allAgents={allAgents}
            person={person.data?.attributes}
            taskFromViewAll={taskFromViewAll}
            editTaskFromViewAll={editTaskFromViewAll}
            openTasksModal={openTasksModal}
            showReassignModal={showReassignModal}
            showRescheduleModal={showRescheduleModal}
            showDeleteModal={showDeleteModal}
            showTaskDetails={showTaskDetails}
          />
        )}
        {taskModalOpen && (
          <TaskModal
            closeTaskModal={closeTaskModal}
            agents={allAgents}
            account_uuid={currentUser.account.uuid}
            user={currentUser}
            taskable={taskable}
            accountMilestoneNames={accountMilestoneNames}
            task={state.selectedTask}
            updateTask={updateQuickViewTask}
            getCardTasks={getTasks}
          />
        )}
        {state.viewDetails && (
          <Details
            task={state.selectedTask}
            closeTaskDetails={closeTaskDetails}
            editTask={editTask}
            editAndComplete={editAndComplete}
            accountMilestoneNames={accountMilestoneNames}
            completeTaskFromDetails={completeTaskFromDetails}
          />
        )}
        {state.showReassignModal && (
          <Reassign
            closeModal={closeReassignModal}
            taskCount={state.selectedTasks.length}
            assignedToUserId={state.assignedToUserId}
            agentsOptions={state.agentsOptions}
            form={state.form}
            dropdownUpdater={reassignAgent}
            reassignTasksHandler={reassignTasks}
            submitDisabled={state.submitDisabled}
          />
        )}
        {state.showRescheduleModal && (
          <Reschedule
            closeModal={closeRescheduleModal}
            taskCount={state.selectedTasks.length}
            rescheduleDateHandler={handleRescheduleDate}
            rescheduleTasksHandler={rescheduleTasks}
            submitDisabled={state.submitDisabled}
          />
        )}
        {state.showDeleteModal && (
          <Delete
            closeModal={closeDeleteModal}
            submitDisabled={state.submitDisabled}
            deleteTasksHandler={deleteTasks}
          />
        )}
      </div>
    </CollapsibleElement>
  );
};

export default CardTasks;
