/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import api from "@shared/phoneDncStatusApi";
import { viewPhoneFromFlat, viewPhoneShape } from "@shared/models/Communications/viewPhone";
import Button from "@shared/v2/Button";
import TextInput from "@shared/v2/TextInput";
import IconButton from "@shared/v2/IconButton";
import TextLink from "@shared/v2/TextLink";
import Alert from "@shared/v2/Alert";
import EmptySmsHistory from "@shared/EmptySmsHistory";
import { ImageSolidV6, SpinnerSolidV6 } from "@shared/v2/Icomoon";
import { Contact, HelperText, LastSeen, placeholderContent } from "@shared/Sms";
import AiSummary from "@shared/AiAssistant/AiSummary";
import MmsMediaModal from "../../shared/MmsMediaModal";
import AiAssistant from "../../shared/AiAssistant/AiAssistant";
import { getAiToken, getMessagingApi } from "../../Inbox/helpers/messagingApi";
import ConversationMessage from "../../Inbox/components/ConversationMessage";
import { transformToTimelineSmsEvent } from "../../Inbox/helpers/transformSms";
import { transformConversation } from "../../reducers/messagingReducer";

const DEFAULT_ERROR =
  "There was a problem loading SMS messages. Please check your connection and try again or contact support by calling 855-427-4848";

class SmsConversation extends Component {
  constructor(props) {
    super(props);
    // This defaultHomeAppTabContent prop is currently being set from the Home App card and is also used in PersonInteractionEmailForm.
    // If we allow other areas of the app to set default email/text content then we should rename this to be more generic. Sending a text with this prop set
    // updates  the person's home_app_invite_sent (called in touchHomeAppInviteSent on line 164 below) so we will need to also include a prop
    // signifying where the default content is being set as we would not want make this update
    this.state = {
      conversation: null,
      newSmsMessage: props.defaultHomeAppTabContent || "",
      newSmsMediaFile: null,
      newSmsMediaPreview: null,
      sendingMessage: false,
      fetchingMessages: false,
      smsMessages: [],
      conversationLoaded: false,
      moreSmsToLoad: true,
      messageForMmsMediaModal: null,
      mediaIndexForMmsMediaModal: null,
      errors: [],
      dncStatuses: {},
      page: 1,
      perPage: 25,
    };
    this.fileInputRef = React.createRef();
  }

  componentDidMount() {
    this.getSmsMessages(() => {
      this.scrollToBottom();
    });
    this.setupSubscription();
    this.getPhoneStatuses();
  }

  componentWillUnmount() {
    this.connection?.stop().catch(console.log);
  }

  handleChange(value) {
    this.setState(() => ({
      newSmsMessage: value,
    }));
  }

  getPhoneStatuses() {
    api.index([this.phoneNumber()]).then((x) => {
      this.setState({ dncStatuses: x.data });
    });
  }

  phoneNumber = () => this.viewPhone().formattedNumber;

  viewPhone = () => {
    const { primaryPhone, personPhoneNumber } = this.props;
    return primaryPhone || viewPhoneFromFlat({ value: personPhoneNumber });
  };

  scrollToBottom = () => {
    this.smsConversationWrapper.scrollTop = this.smsConversationWrapper.scrollHeight;
  };

  clearForm = () => {
    this.setState({
      newSmsMessage: "",
      newSmsMediaFile: null,
      newSmsMediaPreview: null,
      errors: [],
    });
  };

  getSmsMessages = (callback) => {
    const { smsMessages, moreSmsToLoad, fetchingMessages, conversation, page, perPage } = this.state;
    const { personUuid, personPhoneNumber, messagingServiceUrl } = this.props;
    const messagingApi = getMessagingApi(messagingServiceUrl);

    if (!fetchingMessages && moreSmsToLoad) {
      this.setState({ fetchingMessages: true });
      const conversationPromise = conversation
        ? Promise.resolve(conversation)
        : messagingApi
            .post("/Conversation/WithUsers", {
              conversationType: "groupSms",
              isChannel: false,
              users: [{ address: personPhoneNumber, personUuid, isConversationOwner: false }],
            })
            .catch((err) => {
              if (err?.response?.status === 409 && err?.response?.data) {
                return {
                  data: {
                    ...err.response.data,
                    isConflict: true,
                  },
                };
              }
              throw err;
            })
            .then(({ data }) => transformConversation(data));
      conversationPromise
        .then((c) => {
          this.setState({ conversation: c });
          if (!c.uuid || c.isConflict) {
            this.setState({
              conversationLoaded: true,
              fetchingMessages: false,
            });
            return;
          }
          messagingApi
            .get(`/ConversationMessages/${c.uuid}/${page}/${perPage}`)
            .then((res) => {
              const newSmsMessages = res.data.items.reverse();
              this.setState(
                {
                  smsMessages: [...newSmsMessages, ...smsMessages],
                  moreSmsToLoad: newSmsMessages.length === 25,
                  errors: [],
                  fetchingMessages: false,
                  conversationLoaded: true,
                  page: page + 1,
                },
                () => {
                  callback?.();
                },
              );
            })
            .catch((err) => {
              this.setState({
                errors: [err?.response?.data || DEFAULT_ERROR],
                fetchingMessages: false,
              });
            });
        })
        .catch((err) => {
          this.setState({
            errors: [err?.response?.data?.message || DEFAULT_ERROR],
            fetchingMessages: false,
          });
        });
    }
  };

  addMessage = (newMsg) => {
    const { smsMessages, conversation } = this.state;
    const { addSmsToTimeline } = this.props;

    if (smsMessages.find((msg) => msg.uuid === newMsg.uuid)) return;

    this.setState(
      (state) => ({
        smsMessages: [...state.smsMessages, newMsg],
      }),
      () => {
        addSmsToTimeline?.(transformToTimelineSmsEvent(conversation, newMsg));
        this.scrollToBottom();
        this.markAsRead();
      },
    );
  };

  setupSubscription = () => {
    const { messagingServiceUrl } = this.props;
    getAiToken().then((token) => {
      this.connection = new HubConnectionBuilder()
        .withUrl(`${messagingServiceUrl}/MessageHub?access_token=${token}`, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          withCredentials: true,
        })
        .withAutomaticReconnect()
        .configureLogging(process.env.NODE_ENV === "production" ? LogLevel.Error : LogLevel.Debug)
        .build();

      this.connection.on("ActivateConversation", (conversationUuid) => {
        this.connection.send("AddPersonToGroup", conversationUuid);
      });

      this.connection.on("CreateConversation", (conversation) => {
        this.connection.send("AddPersonToGroup", conversation.uuid);
      });

      this.connection.on("NewMessage", (message) => {
        const { conversation } = this.state;
        if (message.conversationUuid === conversation?.uuid) this.addMessage(message);
      });

      this.connection.on("UpdateSmsOrGroupSmsConversationUserMessageStatusOnFailure", (deliveryStatus) => {
        const { messages } = this.state;
        const message = messages.find((o) => o.uuid === deliveryStatus.messageUuid);
        if (message?.deliveryStatuses) {
          message.deliveryStatuses = message.deliveryStatuses.map((s) =>
            s.conversationUserUuid === deliveryStatus.conversationUserUuid ? deliveryStatus : s,
          );
        }
      });

      this.connection.start().catch(console.log);
    });
  };

  markAsRead = () => {
    const { conversation } = this.state;
    const { messagingServiceUrl } = this.props;
    const messagingApi = getMessagingApi(messagingServiceUrl);
    messagingApi.put(`/ConversationUser/ResetUnreadCount/${conversation?.uuid}`).catch(console.log);
  };

  sendSmsMessage = () => {
    const { personId, messagingServiceUrl, defaultHomeAppTabContent, touchHomeAppInviteSent, listingId } =
      this.props;
    const { newSmsMessage, newSmsMediaFile, conversation } = this.state;
    const messagingApi = getMessagingApi(messagingServiceUrl);
    const appInviteNeedsUpdating = !!defaultHomeAppTabContent;

    if (!conversation) return;

    this.setState({ sendingMessage: true }, () => {
      const formData = new FormData();
      formData.append("conversationUuid", conversation.uuid);
      formData.append("messageType", "standard");
      if (newSmsMessage) formData.append("message", newSmsMessage);
      if (newSmsMediaFile) formData.append("attachment", newSmsMediaFile);
      if (listingId) formData.append("attributes", JSON.stringify([{ key: "listing_id", value: listingId }]));

      return messagingApi
        .post("/Message/Sms", formData)
        .then(({ data: message }) => {
          this.addMessage({ ...message, messageType: "sms_out" });
          this.clearForm();
          this.setState({ sendingMessage: false });
          if (appInviteNeedsUpdating) {
            touchHomeAppInviteSent(personId);
          }
        })
        .catch((err) => {
          this.setState({
            sendingMessage: false,
            errors: [err?.response?.data || DEFAULT_ERROR],
          });
        });
    });
  };

  openMmsMediaModal = (message, index) => {
    this.setState({
      messageForMmsMediaModal: message,
      mediaIndexForMmsMediaModal: index,
    });
  };

  closeMmsMediaModal = () => {
    this.setState({
      messageForMmsMediaModal: null,
      mediaIndexForMmsMediaModal: null,
    });
  };

  handleDragOver = (e) => {
    e.preventDefault();
  };

  handleDragDrop = (e) => {
    e.preventDefault();
    this.setState({
      newSmsMediaFile: e.dataTransfer.files[0],
      newSmsMediaPreview: URL.createObjectURL(e.dataTransfer.files[0]),
    });
  };

  onMediaFileChange = (e) => {
    this.setState({
      newSmsMediaFile: e.target.files[0],
      newSmsMediaPreview: URL.createObjectURL(e.target.files[0]),
    });
  };

  closeOrClearBtn = () => {
    const { closeModal } = this.props;
    const { clearForm } = this;
    const phone = this.viewPhone();
    const messageable = !phone.smsUnsubscribed;
    const callback = closeModal || clearForm;
    const text = closeModal ? "Close" : "Clear";

    return (
      <Button schema="tertiary" onClick={callback} size="medium" disabled={!messageable}>
        {text}
      </Button>
    );
  };

  handleScroll = (e) => {
    const { moreSmsToLoad } = this.state;
    if (moreSmsToLoad && e.target.scrollTop === 0) {
      const currentScrollHeight = e.target.scrollHeight;
      this.getSmsMessages(() => {
        this.smsConversationWrapper.scrollTop =
          this.smsConversationWrapper.scrollHeight - currentScrollHeight - 80;
      });
    }
  };

  isDnc = () => {
    const { dncStatuses } = this.state;

    return api.isDnc(this.phoneNumber(), dncStatuses);
  };

  render() {
    const {
      conversation,
      errors,
      smsMessages,
      newSmsMessage,
      conversationLoaded,
      fetchingMessages,
      sendingMessage,
      newSmsMediaFile,
      newSmsMediaPreview,
      messageForMmsMediaModal,
      mediaIndexForMmsMediaModal,
    } = this.state;

    const { personUuid, personName, closeModal, gabbiAi } = this.props;
    const conflictUser = conversation?.conversationUsers?.find((u) => !u.isConversationOwner);
    const phone = this.viewPhone();
    const isDnc = this.isDnc();
    const placeholder = placeholderContent(phone);
    const messageable = !phone.smsUnsubscribed && conversation && !conversation.isConflict;

    return (
      <div className="tw-flex tw-flex-col tw-gap-[16px]">
        <div className="tw-flex tw-justify-between tw-items-center">
          <Contact phone={phone} isDnc={isDnc} personName={personName} />
          <LastSeen smsHistory={smsMessages || []} />
        </div>
        <div
          className="tw-flex tw-flex-col tw-gap-[16px] tw-overflow-auto tw-min-h-[240px] tw-max-h-[330px] tw-border-solid tw-pb-[8px] tw-border-y-1 tw-border-x-0 tw-border-neutral-gray-10"
          ref={(component) => {
            this.smsConversationWrapper = component;
          }}
          onScroll={(e) => this.handleScroll(e)}
        >
          {conversation?.isConflict && (
            <div className="tw-my-[16px]">
              <Alert
                variant="warning"
                title="This conversation already exists"
                text={
                  <div className="tw-flex tw-justify-between tw-items-end">
                    <span>
                      {personName} shares a number with contact {conflictUser.alias}, who you already have an
                      open conversation with.
                    </span>
                    <TextLink
                      href={`/people/${conflictUser.slug}?tab=3`}
                      className="!tw-text-inherit tw-font-semibold tw-uppercase tw-whitespace-nowrap"
                    >
                      Go To Contact
                    </TextLink>
                  </div>
                }
              />
            </div>
          )}
          {fetchingMessages && (
            <div className="tw-flex tw-items-center tw-justify-center tw-gap-[8px] tw-py-[16px] tw-text-gray">
              <SpinnerSolidV6 className="tw-animate-spin" size="l" /> Loading message history...
            </div>
          )}
          <EmptySmsHistory
            containerClassName="tw-flex-grow"
            show={!conversation?.isConflict && conversationLoaded && smsMessages.length === 0}
          />
          {!conversation?.isConflict &&
            smsMessages.map((message, index) => (
              <ConversationMessage
                key={message.uuid}
                message={message}
                conversation={conversation}
                onClickImage={this.openMmsMediaModal}
                showTimeAgo={
                  index === 0 ||
                  moment(message.dateCreated).valueOf() -
                    moment(smsMessages[index - 1]?.dateCreated).valueOf() >
                    7200000
                }
              />
            ))}
        </div>

        {gabbiAi && closeModal && (
          <AiSummary leadPersonUuid={personUuid} allowAddAI={false} variant="inline" />
        )}

        {errors.map((e) => (
          <Alert variant="error" text={e} />
        ))}
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-[8px]">
          {!newSmsMediaFile && messageable && (
            <div onDrop={(e) => this.handleDragDrop(e)} onDragOver={(e) => this.handleDragOver(e)}>
              <IconButton onClick={() => this.fileInputRef.current.click()} size="small" schema="secondary">
                <ImageSolidV6 />
              </IconButton>
            </div>
          )}
          {newSmsMediaFile && (
            <img src={newSmsMediaPreview} className="tw-rounded-10px tw-max-h-80 tw-mb-5" alt="MMS Preview" />
          )}
          <input
            type="file"
            onChange={(e) => this.onMediaFileChange(e)}
            id="file"
            ref={this.fileInputRef}
            style={{ display: "none" }}
          />
          <AiAssistant
            textareaId="sms-conversation-input"
            messageType="SMS"
            contactUuid={personUuid}
            onInsertClick={(text) => this.handleChange(text)}
          />
        </div>
        <TextInput
          id="sms-conversation-input"
          multiline={messageable}
          disabled={!messageable}
          value={newSmsMessage}
          onDrop={(e) => this.handleDragDrop(e)}
          onDragOver={(e) => this.handleDragOver(e)}
          onChange={(e) => this.handleChange(e.target.value)}
          className="tw-resize-none"
          rows={5}
          helperText={<HelperText viewPhone={phone} isDnc={isDnc} />}
          placeholder={placeholder}
        />
        <div className="tw-flex tw-justify-between">
          {this.closeOrClearBtn()}
          <Button
            size="medium"
            schema="primary"
            onClick={this.sendSmsMessage}
            disabled={!messageable}
            isLoading={sendingMessage}
          >
            Send Message
          </Button>
        </div>
        <MmsMediaModal
          onClose={this.closeMmsMediaModal}
          message={messageForMmsMediaModal}
          mediaIndex={mediaIndexForMmsMediaModal}
        />
      </div>
    );
  }
}

export default SmsConversation;

SmsConversation.propTypes = {
  messagingServiceUrl: PropTypes.string.isRequired,
  personUuid: PropTypes.string.isRequired,
  personId: PropTypes.number.isRequired,
  defaultHomeAppTabContent: PropTypes.string,
  touchHomeAppInviteSent: PropTypes.func,
  addSmsToTimeline: PropTypes.func,
  closeModal: PropTypes.func,
  personName: PropTypes.string.isRequired,
  personPhoneNumber: PropTypes.string.isRequired,
  primaryPhone: PropTypes.shape(viewPhoneShape),
  listingId: PropTypes.number,
  gabbiAi: PropTypes.bool,
};

SmsConversation.defaultProps = {
  defaultHomeAppTabContent: null,
  touchHomeAppInviteSent: null,
  addSmsToTimeline: null,
  closeModal: null,
  primaryPhone: null,
  listingId: null,
  gabbiAi: false,
};
