import React, { Component } from "react";
import _ from "lodash";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import AddressView from "@shared/AddressView";
import HeaderTitle from "./HeaderTitle";
import buttons from "../button.module.css";
import { updatePersonPromise } from "../../actions/updatePerson";
import { LOCALITY_LIST } from "../../shared/constants";

class EditAddressesModal extends Component {
  constructor(props) {
    super(props);
    const editablePerson = _.cloneDeep(this.props.person);
    this.state = {
      editablePerson: {
        ...editablePerson,
        included: _.remove(editablePerson.included, (currentObject) => currentObject.id !== null),
      },
      errors: null,
      selectedAddressTypes: [],
    };
  }

  componentDidMount() {
    this.setState({
      selectedAddressTypes: this.props.person.included
        .filter(item => item.type === 'address' && item.id !== null)
        .map(item => _.get(item, 'attributes.address_type'))
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.person !== nextProps.person) {
      this.setState({ editablePerson: JSON.parse(JSON.stringify(nextProps.person)) });
    }
  }

  handleSaveClick = () => {
    updatePersonPromise(this.state.editablePerson)
      .then((r) => {
        this.props.updatePersonData(r.data);
        this.props.closeEditAddressModal();
        this.setState({ errors: null });
      })
      .catch((err) => {
        this.setState({ errors: err.response.data.errors });
      });
  };

  handleIncludedValueChange = (object, value, key) => {
    let includedArray = this.state.editablePerson.included;
    let index = includedArray.indexOf(object);
    includedArray[index]["attributes"][key] = value;
    this.setState({
      editablePerson: {
        ...this.state.editablePerson,
        included: includedArray,
      },
    });
  };

  onAddAddressClick = () => {
    this.setState({
      editablePerson: {
        ...this.state.editablePerson,
        included: [
          ...this.state.editablePerson.included,
          {
            type: "address",
            attributes: {
              address_type: this.labels()[0].value,
              city: "",
              locality: "",
              postal_code: "",
              street_address: "",
              tempId: Date.now(),
            },
          },
        ],
      },
      selectedAddressTypes: [...this.state.selectedAddressTypes, this.labels()[0].value]
    });
  };

  handleRemoveIncludedAttribute = (object) => {
    let includedArray = _.cloneDeep(this.state.editablePerson.included);
    let index = includedArray.indexOf(object);
    includedArray.splice(index, 1);
    this.setState({
      editablePerson: {
        ...this.state.editablePerson,
        included: includedArray,
      },
    });
  };

  labels = () => {
    let options = [
      { label: "Home", value: "home" },
      { label: "Work", value: "work" },
      { label: "Mailing", value: "mailing" },
      { label: "Investment", value: "investment" },
    ];

    options = options.filter((option) => !this.state.selectedAddressTypes.includes(option.value));

    return [...options, { label: "Other", value: "other" }];
  };

  updateIncluded = (tempIdOrId) => (newAttributes) => {
    this.setState(prevState => {
      const editablePersonClone = _.cloneDeep(prevState.editablePerson);

      editablePersonClone.included.forEach(item => {
        const itemId = _.get(item, 'attributes.id');
        const itemTempId = _.get(item, 'attributes.tempId');

        if (itemId === tempIdOrId || itemTempId === tempIdOrId) {
          _.merge(item.attributes, newAttributes);
        }
      });

      return { editablePerson: editablePersonClone };
    });
  }


  render() {
    const { closeEditAddressModal } = this.props;

    return (
      <Modal
        id="edit-addresses-modal"
        className="person-details-modal"
        show={true}
        onHide={closeEditAddressModal}
      >
        <Modal.Header>
          <Modal.Title>
            <HeaderTitle title="Edit Addresses" onClose={closeEditAddressModal} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='!tw-pt-[17px] !tw-px-[42px]'>
          <div>
            {this.state.errors && (
              <div className="tw-mt-[-17px] tw-w-[calc(100% + 20px)] tw-ml-[-10px]">
                <Alert bsStyle="danger">
                  {this.state.errors.map((error) => (
                    <span>
                      <strong>Error: </strong> {error}
                      <br />
                    </span>
                  ))}
                </Alert>
              </div>
            )}
            <Row className="tw-flex tw-flex-col tw-gap-[20px] tw-mt-[-22px] tw-mb-[-20px]">
              {this.state.editablePerson.included
                .filter((d) => d.type === "address" && !d.attributes._destroy)
                .map((address) => {
                  const usableId = address.attributes.id || address.attributes.tempId;
                  const addressType = address.attributes.address_type;

                  // this returns a function that will update the included object with the new attributes
                  const updateAddress = this.updateIncluded(usableId);
                  return (
                    <AddressView
                      currentAddress={{ ...address.attributes, locality: _.toUpper(address.attributes.locality) }}
                      options={_.uniqWith([{ label: _.capitalize(addressType), value: _.toLower(addressType) }, ...this.labels()], _.isEqual)}
                      stateOptions={LOCALITY_LIST}
                      key={usableId}
                      onRemoveAddressHandlr={() => {
                        if (address.attributes.id) {
                          this.setState({
                            editablePerson: {
                              ...this.state.editablePerson,
                              included: this.state.editablePerson.included.map(item => {
                                if (item.attributes.id === address.attributes.id) {
                                  return {
                                    ...item,
                                    attributes: {
                                      ...item.attributes,
                                      _destroy: true
                                    }
                                  }
                                }
                                return item
                              })
                            }

                          })
                          return;
                        }

                        this.handleRemoveIncludedAttribute(address)
                        // update the selected address types, remove the old one
                        this.setState({
                          selectedAddressTypes: [
                            ..._.without(this.state.selectedAddressTypes, addressType),
                          ]
                        })
                      }}
                      onTypeHandlr={(address_type) => {
                        updateAddress({ address_type })
                        // update the selected address types, remove the old one and add the new one
                        this.setState({
                          selectedAddressTypes: [
                            ..._.without(this.state.selectedAddressTypes, addressType),
                            address_type
                          ]
                        })
                      }}
                      onStreetHandlr={(street_address) => updateAddress({ street_address })}
                      onCityHandlr={(city) => updateAddress({ city })}
                      onPostalCodeHandlr={(postal_code) => updateAddress({ postal_code })}
                      onLocalityHandlr={(locality) => updateAddress({ locality })}
                      removable />
                  )
                })
              }
              <Row>
                <Col xs={12}>
                  <button
                    data-cy="edit-addresses-add-address"
                    className={`${buttons.btn} ${buttons.btnSm} ${buttons.btnBlue} tw-mb-[12px]`}
                    onClick={this.onAddAddressClick}
                  >
                    ADD ADDRESS <i className="fa fa-plus"></i>
                  </button>
                </Col>
              </Row>
            </Row>
            <br />
            <Row>
              <Row>
                <Col xs={12}>
                  <div className="d-flex flex-justify-space-between">
                    <a
                      className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnGray} pull-left`}
                      onClick={closeEditAddressModal}
                    >
                      Cancel
                    </a>
                    <a
                      className={`${buttons.btn} ${buttons.btnLg} ${buttons.btnBlue} pull-right`}
                      data-cy="edit-addresses-save"
                      onClick={this.handleSaveClick}
                    >
                      Save
                    </a>
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditAddressesModal;
