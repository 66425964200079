import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { string, bool, func, number } from "prop-types";
import _ from "lodash";
import Modal from "../../../../shared/v2/Modal/Modal";
import RadioButton from "../../../../shared/v2/RadioButton/RadioButton";
import TextInput from "../../../../shared/v2/TextInput/TextInput";
import Dropdown from "../../../../shared/v2/Dropdown/Dropdown";
import Button from "../../../../shared/v2/Button/Button";
import { putDetailsAsThunk } from "../../actions/thunks";
import states from "./states";
import ErrorBanner, { errorForBanner } from "../../../../shared/v2/ErrorBanner";

const isSaveButtonDisabled = ({ title, useCustomTitle, address }) => {
  if (!address.street_address || address.street_address.length > 75) {
    return true;
  }

  if (title && title.length > 40) {
    return true;
  }

  if (useCustomTitle && !title) {
    return true;
  }

  if (!useCustomTitle && (!address.city || !address.locality || !address.postal_code)) {
    return true;
  }

  return false;
};

const EditTitleModal = ({
  uuid,
  addressId,
  rename,
  setRename,
  title,
  useCustomTitle,
  streetAddress,
  city,
  locality,
  postalCode,
  onPutDetailsAsThunk,
  error,
  statusCode,
}) => {
  const [togglecustomTitle, setToggleCustomTitle] = useState(false);
  const [customTitle, setCustomTitle] = useState("");
  // Since we use the trimmed `customTitle` to check for title changes and disabled/error states,
  // we keep a separate state for it here so that we can reuse it,
  // as using a single state for it would prevent the user from being able to type spaces.
  const [customTitleTrimmed, setCustomTitleTrimmed] = useState("");
  const [addressAttributes, setAddress] = useState({});
  const [status, setStatus] = useState(null);

  const errors = useMemo(() => (error ? [errorForBanner(error)] : null), [error]);

  useEffect(() => {
    setAddress({
      street_address: streetAddress,
      city,
      locality,
      postal_code: postalCode,
      id: addressId,
    });
  }, [streetAddress, city, locality, postalCode, addressId]);

  useEffect(() => {
    setCustomTitle(title);
    setCustomTitleTrimmed(title.trim());
  }, [title]);

  useEffect(() => {
    setToggleCustomTitle(useCustomTitle);
  }, [useCustomTitle]);

  useEffect(() => {
    setStatus(statusCode);
  }, [statusCode]);

  useEffect(() => {
    if (status === 200) {
      setRename(false);
      setStatus(null);
    }
  }, [status]);
  const handleSubmit = (e) => {
    const details = [];
    if (useCustomTitle !== togglecustomTitle) {
      details.push({
        key: "use_custom_title",
        value: togglecustomTitle,
        group: "transactionDetails",
      });
    }
    if (title !== customTitleTrimmed) {
      details.push({
        key: "title",
        value: customTitleTrimmed,
        group: "transactionDetails",
      });
    }
    if (
      !_.isEqual(addressAttributes, {
        street_address: streetAddress,
        city,
        locality,
        postal_code: postalCode,
      })
    ) {
      details.push({
        key: "address_attributes",
        value: addressAttributes,
        group: "propertyDetails",
      });
    }
    if (details.length > 0) {
      e.preventDefault();
      onPutDetailsAsThunk({ uuid, details });
    }
  };
  return (
    <Modal
      closeOnClickOutside={false}
      show={rename}
      onHide={() => setRename(false)}
      className="tw-flex tw-items-center tw-justify-center"
      contentClassName="tw-w-[616px]"
      data-cy="header-edit-title-modal"
    >
      <Modal.Header
        title="Edit Title and Address"
        className="tw-text-[28px] tw-leading-9 tw-tracking-normal tw-text-left"
        closeButton
      />

      <Modal.Body className="tw-text-[14px] tw-font-normal tw-text-gray-75 tw-mt-[32px]">
        {errors && <ErrorBanner errors={errors} />}
        <div className="tw-mt-[32px]">
          <RadioButton
            label="Use Custom Title"
            name="custom_title"
            id="address.id"
            checked={togglecustomTitle}
            value=""
            onChange={() => setToggleCustomTitle(!togglecustomTitle)}
            data-cy="header-edit-title-modal-use-custom-title-radio"
          />
        </div>
        <div>
          <RadioButton
            label="Use Address as Title"
            name="address_as_title"
            id="address_as_title"
            checked={!togglecustomTitle}
            value=""
            onChange={() => setToggleCustomTitle(!togglecustomTitle)}
            data-cy="header-edit-title-modal-use-address-title-radio"
          />
        </div>
        <div className="tw-mt-[32px] tw-mb-[32px]">
          <label
            htmlFor="title"
            className="tw-mb-0 tw-whitespace-pre tw-font-bs-sans tw-font-semibold tw-text-14px tw-leading-[38px]"
          >
            Transaction Title {togglecustomTitle && <span className="tw-text-coral">*</span>}
          </label>
          <TextInput
            id="title"
            value={customTitle}
            maxLength={40}
            error={customTitleTrimmed.length >= 40 ? "Max characters: 40" : ""}
            disabled={!togglecustomTitle}
            onChange={(e) => {
              setCustomTitle(e.target.value);
              setCustomTitleTrimmed(e.target.value.trim());
            }}
            className="tw-border-red"
            data-cy="header-edit-title-modal-title-input"
          />
        </div>
        <div className="tw-mb-[24px]">
          <label
            htmlFor="address"
            className="tw-mb-0 tw-whitespace-pre tw-font-bs-sans tw-font-semibold tw-text-14px tw-leading-[38px]"
          >
            Address <span className="tw-text-coral">*</span>
          </label>
          <TextInput
            id="address"
            value={addressAttributes.street_address || ""}
            maxLength={75}
            error={
              addressAttributes.street_address && addressAttributes.street_address.length >= 75
                ? "Max characters: 75"
                : ""
            }
            onChange={(e) => setAddress({ ...addressAttributes, street_address: e.target.value })}
            data-cy="header-edit-title-modal-address-input"
          />
        </div>
        <div className="tw-mb-[24px] tw-flex tw-justify-between">
          <div className="tw-w-[264px]">
            <label
              htmlFor="city"
              className="tw-mb-0 tw-whitespace-pre tw-font-bs-sans tw-font-semibold tw-text-14px tw-leading-[38px]"
            >
              City {!togglecustomTitle && <span className="tw-text-coral">*</span>}
            </label>
            <TextInput
              id="city"
              value={addressAttributes.city || ""}
              onChange={(e) => setAddress({ ...addressAttributes, city: e.target.value })}
              data-cy="header-edit-title-modal-city-input"
            />
          </div>
          <div className="tw-w-[264px]">
            <label
              htmlFor="states"
              className="tw-mb-0 tw-whitespace-pre tw-font-bs-sans tw-font-semibold tw-text-14px tw-leading-[38px]"
            >
              State/Provence {!togglecustomTitle && <span className="tw-text-coral">*</span>}
            </label>
            <Dropdown
              id="states"
              value={{ value: addressAttributes.locality || "", label: addressAttributes.locality || "" }}
              onChange={(e) => setAddress({ ...addressAttributes, locality: e.value })}
              options={states}
              className="tw-bg-gray-5"
              searchable
              data-cy="header-edit-title-modal-state-select"
            />
          </div>
        </div>
        <div className="tw-mb-[24px] tw-w-[264px]">
          <label
            htmlFor="postal_code"
            className="tw-mb-0 tw-whitespace-pre tw-font-bs-sans tw-font-semibold tw-text-14px tw-leading-[38px]"
          >
            Zip/Postal Code {!togglecustomTitle && <span className="tw-text-coral">*</span>}
          </label>
          <TextInput
            id="postal_code"
            value={addressAttributes.postal_code || ""}
            onChange={(e) => setAddress({ ...addressAttributes, postal_code: e.target.value })}
            data-cy="header-edit-title-modal-zip-input"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="tw-mt-[22px]">
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-mt-4px">
          <Button
            schema="tertiary"
            size="medium"
            onClick={() => setRename(false)}
            data-cy="header-edit-title-modal-cancel-button"
          >
            Cancel
          </Button>
          <Button
            disabled={isSaveButtonDisabled({
              title: customTitleTrimmed,
              useCustomTitle: togglecustomTitle,
              address: addressAttributes,
            })}
            schema="primary"
            size="medium"
            onClick={(e) => {
              handleSubmit(e);
              setRename(false);
            }}
            data-cy="header-edit-title-modal-save-button"
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  uuid: state.tdpDetailsReducer.uuid,
  addressId: state.tdpDetailsReducer.headerDetails.address_id,
  title: state.tdpDetailsReducer.transactionDetails.title,
  useCustomTitle: state.tdpDetailsReducer.transactionDetails.use_custom_title,
  streetAddress: state.tdpDetailsReducer.propertyDetails.street_address,
  city: state.tdpDetailsReducer.propertyDetails.city,
  locality: state.tdpDetailsReducer.propertyDetails.state,
  postalCode: state.tdpDetailsReducer.propertyDetails.zip,
  error: state.tdpDetailsReducer.headerDetails.error,
  statusCode: state.tdpDetailsReducer.headerDetails.statusCode,
});

const mapDispatchToProps = (dispatch) => ({
  onPutDetailsAsThunk: (uuid) => dispatch(putDetailsAsThunk(uuid)),
});

EditTitleModal.propTypes = {
  uuid: string.isRequired,
  addressId: number,
  rename: bool.isRequired,
  setRename: func.isRequired,
  title: string,
  useCustomTitle: bool,
  onPutDetailsAsThunk: func.isRequired,
  streetAddress: string,
  city: string,
  locality: string,
  postalCode: string,
  error: string,
  statusCode: number,
};

EditTitleModal.defaultProps = {
  addressId: null,
  title: "",
  useCustomTitle: false,
  streetAddress: "",
  city: "",
  locality: "",
  postalCode: "",
  error: "",
  statusCode: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTitleModal);
